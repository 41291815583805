import { Coder } from "@project-serum/anchor";
import type { AccountParser } from "@saberhq/sail";
import type { KeyedAccountInfo } from "@solana/web3.js";
import type { PoolData, VaultData } from "@sunnyaggregator/sunny-sdk";
import { SunnySaberFarmJSON } from "@sunnyaggregator/sunny-sdk";

const sunnyFarmCoder = new Coder(SunnySaberFarmJSON);

export const PARSE_SS_POOL: AccountParser<PoolData> = (d: KeyedAccountInfo) =>
  sunnyFarmCoder.accounts.decode<PoolData>("Pool", d.accountInfo.data);

export const PARSE_SS_VAULT: AccountParser<VaultData> = (d: KeyedAccountInfo) =>
  sunnyFarmCoder.accounts.decode<VaultData>("Vault", d.accountInfo.data);
