import { Coder } from "@project-serum/anchor";
import type { AccountParser } from "@saberhq/sail";
import type { KeyedAccountInfo } from "@solana/web3.js";
import type {
  MinerData,
  QuarryData,
  RewarderData,
} from "@sunnyaggregator/sunny-sdk";
import { MineJSON } from "@sunnyaggregator/sunny-sdk";

const sunnyMineCoder = new Coder(MineJSON);

export const PARSE_SUNNY_MINER: AccountParser<MinerData> = (
  d: KeyedAccountInfo
) => sunnyMineCoder.accounts.decode<MinerData>("Miner", d.accountInfo.data);

export const PARSE_SUNNY_QUARRY: AccountParser<QuarryData> = (
  d: KeyedAccountInfo
) => sunnyMineCoder.accounts.decode<QuarryData>("Quarry", d.accountInfo.data);

export const PARSE_SUNNY_REWARDER: AccountParser<RewarderData> = (
  d: KeyedAccountInfo
) =>
  sunnyMineCoder.accounts.decode<RewarderData>("Rewarder", d.accountInfo.data);
