import { PublicKey } from "@solana/web3.js";

export const TX_REFETCH_TIME = 1_000;

export const API_BASE = `https://api.saber.so/api/v1`;

/**
 * SUNNY/USDC Serum market ID
 */
export const SERUM_MARKET_ID = new PublicKey(
  "Aubv1QBFh4bwB2wbP1DaPW21YyQBLfgjg8L4PHTaPzRc"
);

/**
 * Program ID of the Serum DEX
 */
export const SERUM_DEX_PROGRAM_ID = new PublicKey(
  "9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin"
);

/**
 * Link to the Bonfida interface
 */
export const SERUM_LINK = `https://dex.bonfida.org/#/market/${SERUM_MARKET_ID.toString()}`;

/**
 * Link to Aldrin SUNNY-USDC
 */
export const ALDRIN_LINK = `https://dex.aldrin.com/chart/spot/SUNNY_USDC`;

/**
 * Link to CoinGecko Sunny
 */
export const COINGECKO_LINK = `https://www.coingecko.com/en/coins/sunny-aggregator`;

/**
 * Link to CoinMarketCap Sunny
 */
export const COINMARKETCAP_LINK = `https://coinmarketcap.com/currencies/sunny-aggregator/`;

/**
 * Key to the Quarry rewarder.
 */
export const QUARRY_REWARDER_KEY = new PublicKey(
  "97PmYbGpSHSrKrUkQX793mjpA2EA9rrQKkHsQuvenU44"
);

export const SABER_REWARDER_KEY = new PublicKey(
  "rXhAofQCT7NN9TUqigyEAUzV1uLL4boeD8CRkNBSkYk"
);

/**
 * Mint of the SBR IOU token.
 */
export const SABER_IOU_MINT = new PublicKey(
  "iouQcQBAiEXe6cKLS85zmZxUqaCqBdeHFpqKoSz615u"
);

export const SABER_REDEEMER_KEY = new PublicKey(
  "CL9wkGFT3SZRRNa9dgaovuRV7jrVVigBUZ6DjcgySsCU"
);

export const SUNNY_IOU_MINT = new PublicKey(
  "SRYWvj5Xw1UoivpdfJN4hFZU1qbtceMvfM5nBc3PsRC"
);

/**
 * Osmosis airdrop
 */
export const OSMOSIS_AIRDROP_MINT = new PublicKey(
  "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag"
);

export const OSMOSIS_AIRDROP_MERKLE_DISTRIBUTOR_KEY = new PublicKey(
  "5rSbevuzFzmtfHobERNtF9sDH6ABrz6dkuSJUBxQu9Pp"
);
