import styled from "@emotion/styled";
import React from "react";

import { breakpoints } from "../../../theme/breakpoints";
import { MainLayout } from "../../layout/MainLayout";
import { Footer } from "./layout/Footer";
import { Header } from "./layout/Header";
import { MigrationWidget } from "./MigrationWidget";
import { OsmosisAirdropWidget } from "./OsmosisAirdropWidget";
import { RotatingNASASun } from "./RotatingNASASun";

export const HomePage: React.FC = () => {
  return (
    <MainLayout>
      <HomePageBg>
        <RotatingNASASun />
        <Header />
        <MainBodyBg>
          <MainBody>
            <MigratorLayout>
              <OsmosisAirdropWidget />
            </MigratorLayout>
            <MigratorLayout>
              <MigrationWidget />
            </MigratorLayout>
            {/* <AllRewards /> */}
            {/* <PoolsSectionV2 /> */}
          </MainBody>
          <Footer />
        </MainBodyBg>
      </HomePageBg>
    </MainLayout>
  );
};

const HomePageBg = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 1400px;
`;

const MigratorLayout = styled.div``;
const MainBodyBg = styled.div`
  // border-top: 1px solid #eaeaeb;
  // box-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
  /* background: #fafafb; */
  /* background: rgba(0, 0, 0, 0.5); */
  position: relative;
  z-index: 2;
  /* backdrop-filter: blur(2px); */
`;
const MainBody = styled.div`
  max-width: 1060px;
  margin: 0 auto;

  /* background: blue; */
  /* background: #fafafb; */

  padding: 1px 32px 0 32px;
  ${breakpoints.mobile} {
    padding: 1px 24px 0 24px;
  }
`;
