import styled from "@emotion/styled";
import { useWalletKit } from "@gokiprotocol/walletkit";
import { useConnectedWallet } from "@saberhq/use-solana";
import React, { useState } from "react";

import { handleException } from "../../utils/error";
import { Button } from "./Button";
import { LoadingSpinner } from "./LoadingSpinner";

interface IProps
  extends Omit<React.ComponentPropsWithRef<typeof Button>, "onClick"> {
  onClick: () => Promise<void> | void;
  loadingMessage?: string;
  connectWalletOverride?: string;
}

export const AsyncButton: React.FC<IProps> = ({
  onClick,
  children,
  disabled,
  loadingMessage,
  connectWalletOverride,
  ...rest
}: IProps) => {
  const wallet = useConnectedWallet();
  const { connect } = useWalletKit();
  const [loading, setLoading] = useState<boolean>(false);
  return wallet !== null ? (
    <Button
      size="large"
      {...rest}
      disabled={disabled || loading}
      onClick={async () => {
        if (loading) {
          return;
        }
        setLoading(true);
        try {
          await onClick();
        } catch (e) {
          handleException(e, { source: "AsyncButton" });
        }
        setLoading(false);
      }}
    >
      {loading ? loadingMessage ?? children : children}
      {loading && (
        <SpinWrapper>
          <LoadingSpinner />
        </SpinWrapper>
      )}
    </Button>
  ) : (
    <Button
      size="large"
      {...rest}
      variant="secondary"
      onClick={() => connect()}
    >
      {connectWalletOverride ?? "Connect Wallet"}
    </Button>
  );
};

const SpinWrapper = styled.span`
  margin-left: 8px;
  height: 20px;
  width: 20px;
  svg {
    height: 18px;
    width: 18px;
  }
`;
