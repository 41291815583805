import type { MinerData, QuarryData } from "@quarryprotocol/quarry-sdk";
import {
  findMinerAddress,
  findQuarryAddress,
} from "@quarryprotocol/quarry-sdk";
import type {
  QuarryInfo,
  QuarryPDAInput,
  RewardsAndRates,
} from "@quarryprotocol/react-quarry";
import {
  PARSE_MINER,
  PARSE_QUARRY,
  QuarryPDA,
  useAllRewards,
  useGetAllClaimableAmounts,
  useQuarryProgramAddresses,
  useRewarder,
  useToken,
  useTokens,
} from "@quarryprotocol/react-quarry";
import type { ParsedAccountDatum, ParsedAccountInfo } from "@saberhq/sail";
import { useParsedAccountsData } from "@saberhq/sail";
import { Fraction, Percent, TokenAmount } from "@saberhq/token-utils";
import { useSolana } from "@saberhq/use-solana";
import type { PublicKey } from "@solana/web3.js";
import type {
  MegaBPS,
  PoolData,
  VaultData,
} from "@sunnyaggregator/sunny-quarry-sdk";
import {
  CREATOR_KEY,
  findPoolAddress,
  findVaultAddress,
  SBR_ADDRESS,
  SUNNY_ADDRESS,
  SUNNY_ADDRESSES,
} from "@sunnyaggregator/sunny-quarry-sdk";
import BN from "bn.js";
import { zip } from "lodash";
import { useEffect, useMemo, useState } from "react";

import {
  QUARRY_REWARDER_KEY,
  SABER_IOU_MINT,
  SABER_REWARDER_KEY,
} from "../../utils/constants";
import { CurrencyMarket } from "../../utils/currencies";
import {
  formatCurrencySmart,
  formatDisplayWithSoftLimit,
} from "../../utils/format";
import { PARSE_POOL, PARSE_VAULT } from "../../utils/parsers";
import type { KnownPool } from "../../utils/useEnvironment";
import { useEnvironment } from "../../utils/useEnvironment";
import { usePrices } from "../prices";
import { useRouter } from "../router";
import { PoolStatus } from ".";

export interface UseAllPools {
  pools: SunnyPool[];
  sbrRewards: RewardsAndRates;
  sunnyRewards: RewardsAndRates;
  tvlUSD: Fraction;
  tvlLoaded: boolean;
}

export interface SunnyPool {
  index: number;

  saberPool: KnownPool;
  sunnyPool: ParsedAccountInfo<PoolData>;
  underlyingQuarry: ParsedAccountInfo<QuarryData>;
  poolQuarry: QuarryInfo;
  claimFee: Percent;
  status: PoolStatus;

  sunnyVault?: ParsedAccountDatum<VaultData>;
  underlyingMiner?: ParsedAccountDatum<MinerData>;
  userSaberMiner?: ParsedAccountDatum<MinerData>;

  poolValueLockedUSD?: Fraction;

  tvlStr: string | null;
  apys: {
    sbr: string | null;
    sunny: string | null;
    combined: string;
  };
}

const TOKENS = [SBR_ADDRESS, SUNNY_ADDRESS];

export const useAllSunnyPools = (): UseAllPools => {
  const { publicKey: userAuthority } = useSolana();
  const { quarries, rewardToken } = useRewarder();
  const { pools: saberPools } = useEnvironment();
  const { usdPerLPTokenMap, underlyingPerLPTokenMap } = useRouter();
  const saberPoolsList = useMemo(() => Object.values(saberPools), [saberPools]);

  const [sbr, sunny] = useTokens(TOKENS);
  const {
    prices: { [CurrencyMarket.SUNNY]: sunnyPriceUSD },
    sbrPriceUSD,
  } = usePrices();

  const [
    {
      saberQuarryKeys,
      sunnyPoolKeys,
      saberMinerKeys,
      sunnyVaultKeys,
      userSaberMinerKeys,
    },
    setKeys,
  ] = useState<{
    saberQuarryKeys: PublicKey[];
    sunnyPoolKeys: PublicKey[];
    saberMinerKeys: (PublicKey | null)[];
    sunnyVaultKeys: (PublicKey | null)[];
    userSaberMinerKeys: (PublicKey | null)[];
  }>({
    saberQuarryKeys: [],
    sunnyPoolKeys: [],
    saberMinerKeys: [],
    sunnyVaultKeys: [],
    userSaberMinerKeys: [],
  });

  useEffect(() => {
    void (async () => {
      const keys = await Promise.all(
        saberPoolsList.map(async (p) => {
          const [saberQuarry] = await findQuarryAddress(
            SABER_REWARDER_KEY,
            p.lpToken.mintAccount
          );
          const [sunnyPool] = await findPoolAddress({
            programId: SUNNY_ADDRESSES.SunnyPoolQuarry,
            creator: CREATOR_KEY,
            quarry: saberQuarry,
          });
          const [sunnyVault] = userAuthority
            ? await findVaultAddress({
                programId: SUNNY_ADDRESSES.SunnyPoolQuarry,
                pool: sunnyPool,
                owner: userAuthority,
              })
            : [null];
          const [saberMiner] = sunnyVault
            ? await findMinerAddress(saberQuarry, sunnyVault)
            : [null];
          const [userSaberMiner] = userAuthority
            ? await findMinerAddress(saberQuarry, userAuthority)
            : [null];
          return {
            saberQuarry,
            saberMiner,
            sunnyPool,
            sunnyVault,
            userSaberMiner,
          };
        })
      );
      setKeys({
        saberQuarryKeys: keys.map((k) => k.saberQuarry),
        sunnyPoolKeys: keys.map((k) => k.sunnyPool),
        saberMinerKeys: keys.map((k) => k.saberMiner),
        sunnyVaultKeys: keys.map((k) => k.sunnyVault),
        userSaberMinerKeys: keys.map((k) => k.userSaberMiner),
      });
    })();
  }, [saberPoolsList, userAuthority]);

  const saberQuarries = useParsedAccountsData(saberQuarryKeys, PARSE_QUARRY);
  const saberMiners = useParsedAccountsData(saberMinerKeys, PARSE_MINER);
  const userSaberMiners = useParsedAccountsData(
    userSaberMinerKeys,
    PARSE_MINER
  );
  const sunnyPools = useParsedAccountsData(sunnyPoolKeys, PARSE_POOL);
  const sunnyVaults = useParsedAccountsData(sunnyVaultKeys, PARSE_VAULT);

  const minerInputs = useMemo(
    () =>
      zip(sunnyPools, sunnyVaults).map(
        ([pool, vault]): QuarryPDAInput<QuarryPDA.MINE_MINER> | null =>
          pool && vault
            ? {
                type: QuarryPDA.MINE_MINER,
                path: [
                  QUARRY_REWARDER_KEY,
                  pool.accountInfo.data.internalMint,
                  vault.accountId,
                ],
              }
            : null
      ) ?? [],
    [sunnyPools, sunnyVaults]
  );
  const minerKeys = useQuarryProgramAddresses(minerInputs);
  const minersData = useParsedAccountsData(minerKeys, PARSE_MINER);

  const saberIOU = useToken(SABER_IOU_MINT);
  const { getAllClaimableAmounts: getAllClaimableAmountsSBR } =
    useGetAllClaimableAmounts({
      token: saberIOU ?? null,
      quarriesData: saberQuarries,
      minersData: saberMiners,
    });
  const sbrRewards = useAllRewards({
    getAllClaimableAmounts: getAllClaimableAmountsSBR,
  });

  const sunnyQuarries = useMemo(
    () =>
      sunnyPools.map((p) =>
        p
          ? quarries.find((q) =>
              q.stakedToken.mintAccount.equals(p.accountInfo.data.internalMint)
            )?.quarry
          : null
      ),
    [quarries, sunnyPools]
  );
  const { getAllClaimableAmounts: getAllClaimableAmountsSunny } =
    useGetAllClaimableAmounts({
      token: rewardToken ?? null,
      quarriesData: sunnyQuarries,
      minersData,
    });
  const sunnyRewards = useAllRewards({
    getAllClaimableAmounts: getAllClaimableAmountsSunny,
  });

  const pools = useMemo(
    () =>
      saberPoolsList
        .map((pool): SunnyPool | null => {
          const sunnyPool = sunnyPools.find((p) =>
            p?.accountInfo.data.vendorMint.equals(pool.lpToken.mintAccount)
          );
          if (!sunnyPool) {
            return null;
          }

          const underlyingQuarryIndex = saberQuarries.findIndex((q) =>
            q?.accountInfo.data.tokenMintKey.equals(pool.lpToken.mintAccount)
          );
          if (underlyingQuarryIndex === -1) {
            return null;
          }
          const underlyingQuarry = saberQuarries[underlyingQuarryIndex];
          const poolQuarryIndex = quarries.findIndex((q) =>
            q.stakedToken.mintAccount.equals(
              sunnyPool.accountInfo.data.internalMint
            )
          );
          if (poolQuarryIndex === -1) {
            return null;
          }
          const poolQuarry = quarries[poolQuarryIndex];
          if (!underlyingQuarry || !poolQuarry) {
            return null;
          }

          const claimFee = new Percent(
            (sunnyPool.accountInfo.data.fees.claimFee as MegaBPS).megaBps,
            new BN(1_000_000_0000)
          );
          const status = (() => {
            // pending = non-zero claim fee + 0 share
            // hidden = 0% claim fee + 0 share
            const hasClaimFee = claimFee && !claimFee.equalTo(0);
            const hasZeroRewards =
              !poolQuarry ||
              poolQuarry?.quarry.accountInfo.data.rewardsShare.isZero();
            if (hasZeroRewards) {
              return hasClaimFee ? PoolStatus.Pending : PoolStatus.Hidden;
            }
            return PoolStatus.Active;
          })();

          const sunnyVault = sunnyVaults.find((v) =>
            v?.accountInfo.data.pool.equals(sunnyPool.accountId)
          );
          const underlyingMiner = saberMiners.find((miner) =>
            miner?.accountInfo.data.quarryKey.equals(underlyingQuarry.accountId)
          );
          const userSaberMiner = userSaberMiners.find((miner) =>
            miner?.accountInfo.data.quarryKey.equals(underlyingQuarry.accountId)
          );

          const { currency } = pool;
          const saberTotalDeposits = new TokenAmount(
            pool.lpToken,
            underlyingQuarry.accountInfo.data.totalTokensDeposited
          );
          const sunnyTotalDeposits = new TokenAmount(
            pool.lpToken,
            sunnyPool.accountInfo.data.totalVendorBalance
          );
          const tvl =
            underlyingPerLPTokenMap[pool.id]?.multiply(sunnyTotalDeposits) ??
            null;
          const tvlStr =
            tvl && currency ? formatCurrencySmart(tvl, currency) : null;
          const poolValueLockedUSD =
            usdPerLPTokenMap[pool.id]?.multiply(sunnyTotalDeposits);

          const saberTVLUSD =
            usdPerLPTokenMap[pool.id]?.multiply(saberTotalDeposits);
          const sunnyTVLUSD = poolValueLockedUSD;
          const { sbrAPY, sunnyAPY, combinedAPY } = (() => {
            if (!saberTVLUSD || !sunnyTVLUSD) {
              return {
                sbrAPY: 0,
                sunnyAPY: 0,
                combinedAPY: 0,
              };
            }

            const sbrPerDay =
              underlyingQuarry.accountInfo.data.annualRewardsRate.div(
                new BN(365)
              );
            const sunnyPerDay =
              poolQuarry.quarry.accountInfo.data.annualRewardsRate.div(
                new BN(365)
              );

            const sbrRewardsPerDayUSD =
              sbrPriceUSD && sbrPerDay && sbr
                ? sbrPriceUSD.multiply(new TokenAmount(sbr, sbrPerDay))
                : new Fraction(0);

            const sunnyRewardsPerDayUSD =
              sunnyPriceUSD.price && sunnyPerDay && sunny
                ? sunnyPriceUSD.price.multiply(
                    new TokenAmount(sunny, sunnyPerDay)
                  )
                : new Fraction(0);

            return {
              sbrAPY: sbrRewardsPerDayUSD
                ? calculateAPY(sbrRewardsPerDayUSD, saberTVLUSD)
                : null,
              sunnyAPY: sunnyRewardsPerDayUSD
                ? calculateAPY(sunnyRewardsPerDayUSD, sunnyTVLUSD)
                : null,
              // apy should be based on the amount you'd get from staking into Sunny
              combinedAPY: calculateCombinedAPY(
                [
                  sunnyRewardsPerDayUSD
                    ? {
                        rewardsPerDayUSD: sunnyRewardsPerDayUSD,
                        tvlUSD: sunnyTVLUSD,
                      }
                    : null,
                  sbrRewardsPerDayUSD
                    ? {
                        rewardsPerDayUSD: sbrRewardsPerDayUSD,
                        tvlUSD: saberTVLUSD,
                      }
                    : null,
                ].filter((x): x is Rewards => !!x)
              ),
            };
          })();

          const sbrAPYStr = sbrAPY
            ? `${formatAPY(sbrAPY)} APY from Saber`
            : null;
          const sunnyAPYStr = sunnyAPY
            ? `${formatAPY(sunnyAPY)} APY from Sunny`
            : null;
          const combinedAPYStr =
            tvl?.equalTo(0) && !sbrAPY
              ? "..."
              : combinedAPY
              ? formatCombinedAPY(combinedAPY)
              : "N/A";

          return {
            index: underlyingQuarryIndex,

            saberPool: pool,
            sunnyPool,
            underlyingQuarry,
            poolQuarry,
            claimFee,
            status,
            poolValueLockedUSD,

            sunnyVault,
            underlyingMiner,
            userSaberMiner,

            tvlStr,
            apys: {
              sbr: sbrAPYStr,
              sunny: sunnyAPYStr,
              combined: combinedAPYStr,
            },
          };
        })
        .filter((p): p is SunnyPool => !!p)
        .sort((a, b) => {
          if (!a.poolValueLockedUSD) {
            return -1;
          }
          if (!b.poolValueLockedUSD) {
            return 1;
          }
          if (a.poolValueLockedUSD.equalTo(b.poolValueLockedUSD)) {
            return a.saberPool.id < b.saberPool.id ? -1 : 1;
          }
          return a.poolValueLockedUSD.greaterThan(b.poolValueLockedUSD)
            ? -1
            : 1;
        }),
    [
      quarries,
      saberMiners,
      saberPoolsList,
      saberQuarries,
      sbr,
      sbrPriceUSD,
      sunny,
      sunnyPools,
      sunnyPriceUSD.price,
      sunnyVaults,
      underlyingPerLPTokenMap,
      usdPerLPTokenMap,
      userSaberMiners,
    ]
  );

  const tvlUSD = useMemo(
    () =>
      pools
        .map((p) => p.poolValueLockedUSD)
        .filter((p): p is Fraction => !!p)
        .reduce(
          (acc, usdAmt) => (usdAmt ? acc.add(usdAmt) : acc),
          new Fraction(0)
        ),
    [pools]
  );

  const tvlLoaded = useMemo(
    () =>
      pools
        .map((p) => p.poolValueLockedUSD)
        .reduce(
          (tvlLoadedResult, usdAmt) => tvlLoadedResult && usdAmt !== undefined,
          true
        ),
    [pools]
  );

  return { pools, sbrRewards, sunnyRewards, tvlUSD, tvlLoaded };
};

interface Rewards {
  rewardsPerDayUSD: Fraction;
  tvlUSD: Fraction;
}

const calculateCombinedAPY = (rewards: Rewards[]): number | null => {
  const dpys = rewards.map(({ rewardsPerDayUSD, tvlUSD }) => {
    if (tvlUSD.equalTo(0)) {
      return 0;
    }
    return parseFloat(rewardsPerDayUSD.divide(tvlUSD).toFixed(10));
  });
  const dpy = dpys.reduce((acc, el) => acc + el);
  return (dpy + 1) ** 365 - 1;
};

const calculateAPY = (
  rewardsPerDayUSD: Fraction,
  tvlUSD: Fraction
): number | null => {
  return calculateCombinedAPY([{ rewardsPerDayUSD, tvlUSD }]);
};

const formatAPY = (apy: number): string =>
  apy > 90 // 90 = 9000%
    ? `>${formatDisplayWithSoftLimit(9000, 1, 1)}%`
    : apy > 0 && apy < 0.0001
    ? "<0.01%"
    : `${formatDisplayWithSoftLimit(apy * 100, 1, 3)}%`;

const formatCombinedAPY = (apy: number): string =>
  apy > 90 // 90 = 9000%
    ? `>${formatDisplayWithSoftLimit(9000, 1, 1)}%`
    : `${formatDisplayWithSoftLimit(apy * 100, 1, 2)}%`;
