import { useToken } from "@quarryprotocol/react-quarry";
import type { Token } from "@saberhq/token-utils";
import { SBR_ADDRESS } from "@sunnyaggregator/sunny-sdk";

/**
 * Governance token loading
 * @returns
 */
export const useGovernanceToken = (): Token | null | undefined => {
  return useToken(SBR_ADDRESS);
};
