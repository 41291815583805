import "react-app-polyfill/stable";

import { ThemeProvider } from "@emotion/react";
import type {
  SailError,
  SailGetMultipleAccountsError,
  SailTransactionError,
  SailUnknownTXFailError,
  UseSailArgs,
} from "@saberhq/sail";
import { SailProvider } from "@saberhq/sail";
import * as Sentry from "@sentry/react";
import type { PublicKey } from "@solana/web3.js";
import { SUNNY_ADDRESSES, SUNNY_IDLS } from "@sunnyaggregator/sunny-sdk";
import { mapValues } from "lodash";
import React from "react";
import invariant from "tiny-invariant";

import { App } from "./App";
import { ConfigProvider } from "./contexts/config";
import { PricesProvider } from "./contexts/prices";
import { SunnyQuarryProvider } from "./contexts/quarry";
import { RouterProvider } from "./contexts/router";
import type { ProgramKey } from "./contexts/sdk";
import { SDKProvider } from "./contexts/sdk";
import { SunnyPoolsProvider } from "./contexts/sunnyPools";
import { WalletConnectorProvider } from "./contexts/wallet";
import { theme } from "./theme";
import { parseIdlErrors, ProgramError } from "./utils/anchorError";
import { notify } from "./utils/notifications";
import { EnvironmentProvider } from "./utils/useEnvironment";
import { handleException } from "./utils/error";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
const programErrors = mapValues(SUNNY_IDLS, (prog) => parseIdlErrors(prog));

const programIDs = Object.entries(SUNNY_ADDRESSES).reduce(
  (acc, [name, prog]: [name: string, prog: PublicKey]) => ({
    ...acc,
    [prog.toString()]: name,
  }),
  {}
) as Record<string, ProgramKey>;

const onTxSend: UseSailArgs["onTxSend"] = ({ network, pending, message }) => {
  notify({
    message,
    txids: pending.map((p) => p.signature),
    env: network,
  });
};

const onTxError = (error: SailTransactionError) => {
  // Log the program error
  const err = error.originalError;

  if (!(err instanceof Error)) {
    let errorString = "unknown originalError";
    try {
      errorString = (error.originalError as Error).toString();
    } catch (e) {}

    notify({
      message: `Unexpected error`,
      description: error.message + " " + errorString,
      env: error.network,
      type: "error",
    });

    const sentryArgs = {
      tags: {
        "tx.error": error.tag,
      },
      fingerprint: error.fingerprint,
    } as const;
    console.error(error, sentryArgs);
    Sentry.captureException(error, sentryArgs);
    return;
  }

  const { tx } = error;
  if (err.toString().toLowerCase().includes(": custom program error:")) {
    // todo: figure out the duplicates
    const inspectLink = tx.generateInspectLink(
      error.network !== "localnet" ? error.network : "mainnet-beta"
    );
    console.error(`Inspect TX: ${inspectLink}`);

    console.error("Transaction", tx);
    const progError = ProgramError.parse(err, tx, programIDs, programErrors);
    if (progError) {
      const message = err.message.split(":")[1] ?? "Transaction failed";
      notify({
        message,
        description: `${progError.message}`,
        env: error.network,
        type: "error",
      });
      const sentryArgs = {
        tags: {
          program: progError.program ?? "AnchorInternal",
          "program.error.code": progError.code,
          "program.error.name": progError.errorName,
        },
        extra: {
          progError,
          message,
          originalError: err,
        },
      } as const;
      console.error(progError, sentryArgs);
      Sentry.captureException(progError, sentryArgs);
      return;
    }
  }

  if (/(.+)?: custom program error: 0x1$/.exec(err.message.toString())) {
    notify({
      message: `Insufficient SOL (need more SOL)`,
      description: error.message,
      env: error.network,
      type: "error",
    });
  } else if (err.message.includes("Signature request denied")) {
    notify({
      message: `Transaction cancelled`,
      description: error.message,
      env: error.network,
      type: "info",
    });
    return;
  } else {
    notify({
      message: `Transaction failed (try again later)`,
      description: error.message,
      env: error.network,
      type: "error",
    });
    const inspectLink = tx.generateInspectLink(
      error.network !== "localnet" ? error.network : "mainnet-beta"
    );
    console.error(`Inspect TX: ${inspectLink}`);
  }
  const sentryArgs = {
    tags: {
      "tx.error": error.tag,
    },
    fingerprint: error.fingerprint,
  } as const;
  console.error(error, sentryArgs);
  Sentry.captureException(error, sentryArgs);
};

const onSailError = (err: SailError) => {
  switch (err.name) {
    case "SailTransactionError":
      onTxError(err as SailTransactionError);
      break;
    case "SailUnknownTXFailError": {
      const failErr = err as SailUnknownTXFailError;
      failErr.txs.forEach((tx, i) => {
        console.error(`TX #${i}:`, tx.debugStr);
      });
      handleException(err, {
        userMessage: {
          title: "Transaction failed",
        },
        tags: {
          network: failErr.network,
        },
      });
      break;
    }
    case "SailGetMultipleAccountsError":
      handleException(err, {
        userMessage: {
          title: "Error fetching data",
        },
      });
      break;
    default:
      console.error("Sail error", err);
      Sentry.captureException(err);
  }
};

const sailState: UseSailArgs = {
  onTxSend,
  onSailError,
};

export const AppWithProviders: React.FC = () => {
  return (
    <React.StrictMode>
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          <WalletConnectorProvider>
            <EnvironmentProvider>
              <SailProvider initialState={sailState}>
                <SDKProvider>
                  <PricesProvider>
                    <RouterProvider>
                      <SunnyQuarryProvider>
                        <QueryClientProvider
                          contextSharing={true}
                          client={queryClient}
                        >
                          <SunnyPoolsProvider>
                            <App />
                          </SunnyPoolsProvider>
                        </QueryClientProvider>
                      </SunnyQuarryProvider>
                    </RouterProvider>
                  </PricesProvider>
                </SDKProvider>
              </SailProvider>
            </EnvironmentProvider>
          </WalletConnectorProvider>
        </ThemeProvider>
      </ConfigProvider>
    </React.StrictMode>
  );
};
