import { Coder } from "@project-serum/anchor";
import type { AccountParser } from "@saberhq/sail";
import type { PoolData, VaultData } from "@sunnyaggregator/sunny-quarry-sdk";
import { SUNNY_IDLS } from "@sunnyaggregator/sunny-quarry-sdk";

const sunnyQuarryCoder = new Coder(SUNNY_IDLS.SunnyPoolQuarry);

export const PARSE_POOL: AccountParser<PoolData> = (d) =>
  sunnyQuarryCoder.accounts.decode<PoolData>("Pool", d.accountInfo.data);

export const PARSE_VAULT: AccountParser<VaultData> = (d) =>
  sunnyQuarryCoder.accounts.decode<VaultData>("Vault", d.accountInfo.data);
