import type { RewardsAndRates } from "@quarryprotocol/react-quarry";
import type { ParsedAccountDatum, ParsedAccountInfo } from "@saberhq/sail";
import { useParsedAccountsData } from "@saberhq/sail";
import type { Fraction } from "@saberhq/token-utils";
import { TokenAmount } from "@saberhq/token-utils";
import type { PublicKey } from "@solana/web3.js";
import type {
  Farmer,
  MegaBPS,
  MinerData,
  Plot,
  PoolData,
  QuarryData,
  VaultData,
} from "@sunnyaggregator/sunny-sdk";
import { LANDLORD_KEY } from "@sunnyaggregator/sunny-sdk";
import { zip } from "lodash";
import { useMemo } from "react";
import { createContainer } from "unstated-next";

import type { PoolID } from "../../utils/environments";
import {
  PARSE_SUNNY_MINER,
  PARSE_SUNNY_QUARRY,
} from "../../utils/farming/useMineParsers";
import { PARSE_FARMER, PARSE_PLOT } from "../../utils/farming/useSaberParsers";
import {
  PARSE_SS_POOL,
  PARSE_SS_VAULT,
} from "../../utils/farming/useSunnyParsers";
import type { KnownPool } from "../../utils/useEnvironment";
import { useEnvironment } from "../../utils/useEnvironment";
import type { ProgramAddressInput } from "../../utils/useProgramAddresses";
import {
  ProgramAddressType,
  useProgramAddresses,
} from "../../utils/useProgramAddresses";
import { useRouter } from "../router";
import { useSDK } from "../sdk";
import type { SunnyPool } from "./useAllSunnyPools";
import { useAllSunnyPools } from "./useAllSunnyPools";

export enum PoolStatus {
  Hidden = "hidden",
  Pending = "pending",
  Active = "active",
}
export interface LegacySunnyPool {
  index: number;
  key: PublicKey;
  poolID: PoolID;
  pool: KnownPool;
  plot: ParsedAccountInfo<Plot>;
  farmer: Farmer | null;

  vault: ParsedAccountDatum<VaultData>;
  vaultFarmer: ParsedAccountDatum<Farmer>;
  sunnyPool: ParsedAccountInfo<PoolData>;

  quarry: ParsedAccountInfo<QuarryData>;
  /**
   * miner vault
   */
  miner: ParsedAccountDatum<MinerData>;

  status: PoolStatus;
  poolValueLockedUSD?: Fraction;
}

/**
 * useAllPlots
 * @returns
 */
const useSunnyPoolsInternal = (): {
  loading: boolean;
  sunnyPools: LegacySunnyPool[];
  tvlUSD?: Fraction;
  tvlLoaded: boolean;
  pools: SunnyPool[];

  sbrRewards: RewardsAndRates;
  sunnyRewards: RewardsAndRates;
} => {
  const {
    pools: newSunnyPools,
    sbrRewards,
    sunnyRewards,
    tvlUSD,
    tvlLoaded,
  } = useAllSunnyPools();

  const { pools } = useEnvironment();
  const poolsList = useMemo(() => Object.values(pools), [pools]);

  const landlordKey = LANDLORD_KEY;
  const { sunny, sunnyMut } = useSDK();
  const owner = sunnyMut?.provider.wallet.publicKey;

  const plotInputs = useMemo(
    () =>
      poolsList.map(
        (pool): ProgramAddressInput => ({
          type: ProgramAddressType.PLOT,
          path: [landlordKey, pool.lpToken.mintAccount],
        })
      ),
    [landlordKey, poolsList]
  );
  const plotKeys = useProgramAddresses(plotInputs);
  const plotsData = useParsedAccountsData(plotKeys, PARSE_PLOT);

  const farmerInputs = useMemo(
    () =>
      poolsList.map((pool): ProgramAddressInput | null =>
        owner
          ? {
              type: ProgramAddressType.FARMER,
              path: [landlordKey, pool.lpToken.mintAccount, owner],
            }
          : null
      ),
    [landlordKey, owner, poolsList]
  );
  const farmerKeys = useProgramAddresses(farmerInputs);
  const farmersData = useParsedAccountsData(farmerKeys, PARSE_FARMER);

  const poolInputs = useMemo(
    () =>
      poolsList.map((pool): ProgramAddressInput => {
        return {
          type: ProgramAddressType.SS_POOL,
          path: [landlordKey, pool.lpToken.mintAccount],
        };
      }),
    [landlordKey, poolsList]
  );
  const poolKeys = useProgramAddresses(poolInputs);
  const poolsData = useParsedAccountsData(poolKeys, PARSE_SS_POOL);

  const vaultInputs = useMemo(
    () =>
      poolsList.map((pool): ProgramAddressInput | null =>
        owner
          ? {
              type: ProgramAddressType.SS_VAULT,
              path: [landlordKey, pool.lpToken.mintAccount, owner],
            }
          : null
      ),
    [landlordKey, owner, poolsList]
  );
  const vaultKeys = useProgramAddresses(vaultInputs);
  const vaultsData = useParsedAccountsData(vaultKeys, PARSE_SS_VAULT);

  const vaultFarmerInputs = useMemo(
    () =>
      zip(poolsList, vaultKeys).map(
        ([pool, vaultKey]): ProgramAddressInput | null =>
          pool && vaultKey
            ? {
                type: ProgramAddressType.FARMER,
                path: [landlordKey, pool.lpToken.mintAccount, vaultKey],
              }
            : null
      ),
    [landlordKey, poolsList, vaultKeys]
  );
  const vaultFarmerKeys = useProgramAddresses(vaultFarmerInputs);
  const vaultFarmersData = useParsedAccountsData(vaultFarmerKeys, PARSE_FARMER);

  const quarryInputs = useMemo(
    () =>
      poolsData.map((poolData): ProgramAddressInput | null =>
        poolData
          ? {
              type: ProgramAddressType.MINE_QUARRY,
              path: [
                sunny.environment.rewarder,
                poolData.accountInfo.data.farmMint,
              ],
            }
          : null
      ),
    [poolsData, sunny.environment.rewarder]
  );
  const quarryKeys = useProgramAddresses(quarryInputs);
  const quarriesData = useParsedAccountsData(quarryKeys, PARSE_SUNNY_QUARRY);

  const minerInputs = useMemo(
    () =>
      zip(poolsData, vaultKeys).map(
        ([
          poolData,
          vaultKey,
        ]): ProgramAddressInput<ProgramAddressType.MINE_MINER> | null =>
          poolData && vaultKey
            ? {
                type: ProgramAddressType.MINE_MINER,
                path: [
                  sunny.environment.rewarder,
                  poolData.accountInfo.data.farmMint,
                  vaultKey,
                ],
              }
            : null
      ),
    [poolsData, sunny.environment.rewarder, vaultKeys]
  );
  const minerKeys = useProgramAddresses(minerInputs);
  const minersData = useParsedAccountsData(minerKeys, PARSE_SUNNY_MINER);

  const { usdPerLPTokenMap } = useRouter();

  const plots = useMemo(() => {
    const sunnyData = zip(poolsData, quarriesData, minersData);
    const vaultsAndFarmers = zip(vaultsData, vaultFarmersData);

    return zip(plotsData, poolsList, farmersData, sunnyData, vaultsAndFarmers)
      .map(
        (
          [plotData, pool, farmerData, sunnyDatum, vaultAndFarmerData],
          i
        ): LegacySunnyPool | null => {
          if (!pool || !plotData || !vaultAndFarmerData || !sunnyDatum) {
            return null;
          }
          const [poolData, quarryData, minerData] = sunnyDatum;
          if (!poolData || !quarryData) {
            return null;
          }
          const [vaultData, vaultFarmerData] = vaultAndFarmerData;

          const poolValueLockedUSD = usdPerLPTokenMap[pool.id]?.multiply(
            new TokenAmount(
              pool.lpToken,
              poolData.accountInfo.data.totalLpTokenBalance
            )
          );

          // pending = non-zero claim fee + 0 share
          // hidden = 0% claim fee + 0 share
          const hasClaimFee = !(
            poolData.accountInfo.data.fees.claimFee as MegaBPS
          ).megaBps.isZero();
          const hasZeroRewards =
            quarryData.accountInfo.data.rewardsShare.isZero();
          const status = (() => {
            if (hasZeroRewards) {
              return hasClaimFee ? PoolStatus.Pending : PoolStatus.Hidden;
            }
            return PoolStatus.Active;
          })();

          return {
            index: i,
            key: plotData.accountId,
            poolID: pool.id,
            pool,
            plot: plotData,
            farmer: farmerData?.accountInfo.data ?? null,
            vault: vaultData,
            vaultFarmer: vaultFarmerData,
            sunnyPool: poolData,
            quarry: quarryData,
            miner: minerData,
            status,
            poolValueLockedUSD,
          };
        }
      )
      .filter((p): p is LegacySunnyPool => !!p);
  }, [
    farmersData,
    minersData,
    plotsData,
    poolsData,
    poolsList,
    quarriesData,
    usdPerLPTokenMap,
    vaultFarmersData,
    vaultsData,
  ]);

  const loading = useMemo(
    () =>
      plotsData.findIndex((p) => p === undefined) !== -1 ||
      farmersData.findIndex((f) => f === undefined) !== -1,
    [farmersData, plotsData]
  );

  const totalTVLUSD = useMemo(
    () =>
      plots
        .map((p) => p.poolValueLockedUSD)
        .filter((p): p is Fraction => !!p)
        .reduce((acc, usdAmt) => (usdAmt ? acc.add(usdAmt) : acc), tvlUSD),
    [plots, tvlUSD]
  );

  return {
    loading,
    sunnyPools: plots,
    tvlUSD: totalTVLUSD,
    tvlLoaded,

    pools: newSunnyPools,

    sbrRewards,
    sunnyRewards,
  };
};

export const { Provider: SunnyPoolsProvider, useContainer: useSunnyPools } =
  createContainer(useSunnyPoolsInternal);
