import type { AccountData } from "@cosmjs/launchpad";
import { SignerWallet, SolanaProvider } from "@saberhq/solana-contrib";
import { useConnectionContext } from "@saberhq/use-solana";
import { u64 } from "@solana/spl-token";
import type { PublicKey } from "@solana/web3.js";
import { Keypair } from "@solana/web3.js";
import type { ClaimStatus } from "@sunnyaggregator/osmosis-merkle-distributor";
import {
  findClaimStatusKey,
  MerkleDistributorSDK,
} from "@sunnyaggregator/osmosis-merkle-distributor";
import { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";

import { OSMOSIS_AIRDROP_MERKLE_DISTRIBUTOR_KEY } from "../../utils/constants";
import { notify } from "../../utils/notifications";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function responseIsValid(value: Record<string, any>): value is AirdropResponse {
  if (typeof value === "object" && value !== null) {
    const recordValue: Record<string, unknown> = value;
    if (typeof recordValue.index !== "number") {
      console.log("Invalid index");
      return false;
    }
    if (typeof recordValue.amount !== "string") {
      console.log("Invalid amount");
      return false;
    }
    if (!Array.isArray(recordValue.proof)) {
      console.log("Invalid proof");
      return false;
    }
    for (const proofElement of recordValue.proof) {
      if (typeof proofElement !== "string") {
        console.log("Invalid proof element");
        return false;
      }
    }
    return true;
  }

  return false;
}

// We first attempt to fetch from the cloudflare hosted proof files. If that
// doesn't work, we get from github.
const generateAirdropProofLink = (osmosisAddress: string): string =>
  `https://osmosis-airdrop-artifacts.sunny.ag/proofs/${osmosisAddress}.json`;

const generateAirdropProofLinkAlternate = (osmosisAddress: string): string =>
  `https://raw.githubusercontent.com/SunnyAggregator/osmosis-airdrop-artifacts/master/proofs/${osmosisAddress}.json`;
const responseCache = new Map<string, AirdropResponse | null>();

// using react-query because swr sucks (it doesn't work with multiple items or at least i couldnt figure it out)
// actually it might work with multiple items, but it's trash
const vaultAirdropQueryFetcher = async (
  osmosisAddress: string
): Promise<AirdropResponse | null> => {
  const url = generateAirdropProofLink(osmosisAddress);
  try {
    const cacheResult = responseCache.get(url);
    if (cacheResult) {
      return Promise.resolve({
        index: cacheResult.index,
        amount: cacheResult.amount,
        proof: cacheResult.proof,
        osmosisAddress,
      });
    } else if (cacheResult === null) {
      return null;
    }
    const response = await fetch(url);

    if (response.status === 200) {
      // console.log(response);
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const responseJson = await response.json();
        if (responseIsValid(responseJson)) {
          responseCache.set(url, responseJson);
          return {
            index: responseJson.index,
            amount: responseJson.amount,
            proof: responseJson.proof,
            osmosisAddress,
          };
        }
        console.warn("Invalid file response: ", responseJson);
      } catch (e) {
        console.warn("Invlid server response: ", e);
        return Promise.resolve(null);
      }
    }
    console.log("OK so that didn't work");
    if (response.status === 404) {
      responseCache.set(url, null);
      return Promise.resolve(null);
    }

    console.log("Unhandled response type", response);
    return Promise.resolve(null);
  } catch (e) {
    console.log("Unable to fetch proof from sunny.ag", e);
    console.log(
      "Will attempt to fetch from github.com/SunnyAggregator/osmosis-airdrop-artifacts"
    );
  }

  try {
    const altResponse = await fetch(
      generateAirdropProofLinkAlternate(osmosisAddress)
    );

    if (altResponse.status === 200) {
      // console.log(response);
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const altResponseJson = await altResponse.json();
        if (responseIsValid(altResponseJson)) {
          responseCache.set(url, altResponseJson);
          return {
            index: altResponseJson.index,
            amount: altResponseJson.amount,
            proof: altResponseJson.proof,
            osmosisAddress,
          };
        }
        console.error("Invalid file response: ", altResponseJson);
      } catch (e) {
        console.error("Invlid server response: ", e);
        return Promise.resolve(null);
      }
    }
    if (altResponse.status === 404) {
      responseCache.set(url, null);
      return Promise.resolve(null);
    }
  } catch (e) {
    notify({
      type: "error",
      message: `Unable to fetch proof: ${String(e)}`,
    });
    return null;
  }
};

export type AirdropResponse = {
  amount: string;
  index: number;
  proof: string[];
  osmosisAddress: string;
};

export type UseOsmosisAirdropResult = {
  airdropResponse: AirdropResponse | undefined;
  isLoading: boolean;
  airdropAmount: number;
  claimedAlready: string | true | false;
  claimFinishedCallback: (osmosisAddress: string) => void;
};

export const useOsmosisAirdrop = (
  osmosisAccount: AccountData | undefined
  // customProof?: string
): UseOsmosisAirdropResult => {
  const { connection, sendConnection } = useConnectionContext();
  const readonlyProvider = useMemo(() => {
    return SolanaProvider.load({
      connection,
      sendConnection,
      wallet: new SignerWallet(Keypair.generate()),
      opts: {
        commitment: "recent",
      },
    });
  }, [connection, sendConnection]);
  const sdk = useMemo(() => {
    return MerkleDistributorSDK.load({ provider: readonlyProvider });
  }, [readonlyProvider]);

  const relevantOsmosisAddresses = osmosisAccount
    ? [osmosisAccount.address]
    : [];

  const airdropQueryResults = useQueries(
    relevantOsmosisAddresses.map((osmosisAddress) => {
      return {
        queryKey: [osmosisAddress],
        queryFn: () => vaultAirdropQueryFetcher(osmosisAddress),
      };
    })
  );

  const zerothAirdropResponseQuery = airdropQueryResults[0];

  let airdropResponse: AirdropResponse | null | undefined;
  if (zerothAirdropResponseQuery?.isFetched) {
    // TODO: Handle sad case
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const airdropResultData: any | null = zerothAirdropResponseQuery.data;
    if (airdropResultData === null) {
      airdropResponse = null;
    } else {
      if (responseIsValid(airdropResultData)) {
        airdropResponse = airdropResultData;
      }
    }
  }
  const osmosisAddress = osmosisAccount?.address;

  const [lastOsmosisAddress, setLastOsmosisAddress] = useState<
    string | undefined
  >(undefined);
  const [statusKeys, setStatusKey] = useState<PublicKey[]>([]);
  useEffect(() => {
    void (async () => {
      if (lastOsmosisAddress === osmosisAddress) {
        return;
      }
      if (airdropResponse) {
        const [claimStatus] = await findClaimStatusKey(
          new u64(airdropResponse.index),
          OSMOSIS_AIRDROP_MERKLE_DISTRIBUTOR_KEY
        );
        setLastOsmosisAddress(osmosisAddress);
        setStatusKey([claimStatus]);
      } else {
        return null;
      }
    })();
  }, [
    osmosisAddress,
    airdropQueryResults,
    airdropResponse,
    lastOsmosisAddress,
  ]);

  const [claimStatusData, setClaimStatusData] = useState<
    ClaimStatus | null | undefined
  >(undefined);

  const [didClaim, setDidClaim] = useState<boolean>(false);

  useEffect(() => {
    async function fetchClaimStatus() {
      if (statusKeys[0]) {
        try {
          const claimStatusData = await sdk.program.account.claimStatus.fetch(
            statusKeys[0]
          );
          console.log("claimStatusData", claimStatusData);
          setClaimStatusData(claimStatusData);
        } catch (e) {
          setClaimStatusData(null);
          console.log(
            "Successfully failed! Error means the airdrop was not yet claimed:",
            e
          );
        }
      }
    }
    void fetchClaimStatus();
  }, [sdk.program.account.claimStatus, statusKeys]);

  if (airdropResponse === null) {
    // Definitely means nothing to see.
    return {
      airdropResponse: undefined,
      isLoading: false,
      airdropAmount: 0,
      claimedAlready: false,
      claimFinishedCallback: () => {
        return;
      },
    };
  }

  const isLoading =
    osmosisAddress &&
    (statusKeys[0] === undefined || claimStatusData === undefined);

  if (isLoading || airdropResponse === undefined) {
    return {
      airdropResponse: undefined,
      isLoading: true,
      airdropAmount: 0,
      claimedAlready: false,
      claimFinishedCallback: () => {
        return;
      },
    };
  }

  if (didClaim) {
    return {
      airdropResponse: airdropResponse,
      isLoading: false,
      airdropAmount: Number(airdropResponse.amount) / 1000000,
      claimedAlready: true,
      claimFinishedCallback: () => {
        return;
      },
    };
  }

  if (claimStatusData) {
    // console.log(zerothStatusKey);
    // not null nor undefined, meaning claimed!
    return {
      airdropResponse: airdropResponse,
      isLoading: false,
      airdropAmount: Number(airdropResponse.amount) / 1000000,
      claimedAlready: claimStatusData.claimant.toBase58(),
      claimFinishedCallback: () => {
        return;
      },
    };
  }

  if (airdropResponse === null) {
    return {
      airdropResponse: undefined,
      isLoading: false,
      airdropAmount: 0,
      claimedAlready: false,
      claimFinishedCallback: () => {
        return;
      },
    };
  }

  return {
    airdropResponse: airdropResponse,
    isLoading: false,
    airdropAmount: Number(airdropResponse.amount) / 1000000,
    claimedAlready: false,
    claimFinishedCallback: () => {
      setDidClaim(true);
    },
  };
};

export const useDistributorAccountExists = (
  distributor: PublicKey
): boolean => {
  const [distributorAccountExists, setDistributorAccountExists] =
    useState<boolean>(false);
  const { connection, sendConnection } = useConnectionContext();

  useEffect(() => {
    async function fetchDistributor() {
      const readonlyProvider = SolanaProvider.load({
        connection,
        sendConnection,
        wallet: new SignerWallet(Keypair.generate()),
        opts: {
          commitment: "recent",
        },
      });
      const sdk = MerkleDistributorSDK.load({ provider: readonlyProvider });

      try {
        const distributorA = await sdk.program.account.merkleDistributor.fetch(
          distributor
        );
        if (window.location.hash === "#analytics") {
          console.log(distributorA);
          console.log(
            "Accounts claimed:",
            distributorA.numNodesClaimed.toString()
          );
          console.log(
            "SUNNY total claimed:",
            Number(distributorA.totalAmountClaimed.toString()) / 1_000_000
          );
        }
        setDistributorAccountExists(true);
      } catch (e) {
        console.log(e);
      }
    }
    void fetchDistributor();
  }, [connection, distributor, sendConnection]);
  return distributorAccountExists;
};
