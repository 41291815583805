import { useParsedAccountData } from "@saberhq/sail";
import { TokenAmount } from "@saberhq/token-utils";
import type { PublicKey } from "@solana/web3.js";
import type { Landlord } from "@sunnyaggregator/sunny-sdk";
import { LANDLORD_KEY } from "@sunnyaggregator/sunny-sdk";
import { useMemo } from "react";

import { useSDK } from "../../contexts/sdk";
import { useGovernanceToken } from "./useGovernanceToken";
import { PARSE_LANDLORD } from "./useSaberParsers";

export const useLandlordState = (): {
  loading: boolean;
  state:
    | (Landlord & {
        programID: PublicKey;
        landlordAddress: PublicKey;
      })
    | null;
  dailyRewardsRate: TokenAmount | null;
  landlordKey: PublicKey;
} => {
  const token = useGovernanceToken();
  const { sunny } = useSDK();
  const farmProgram = sunny.programs.Farm;
  const { data: landlordData, loading: landlordLoading } = useParsedAccountData(
    LANDLORD_KEY,
    PARSE_LANDLORD
  );

  const state = useMemo(() => {
    if (!landlordData) {
      return null;
    }
    return {
      ...landlordData.accountInfo.data,
      programID: farmProgram.programId,
      landlordAddress: landlordData.accountId,
    };
  }, [farmProgram, landlordData]);

  const dailyRewardsRate = useMemo(
    () =>
      token && state ? new TokenAmount(token, state.dailyRewardsRate) : null,
    [state, token]
  );

  return {
    loading: token === undefined || landlordLoading,
    state,
    dailyRewardsRate,
    landlordKey: LANDLORD_KEY,
  };
};
