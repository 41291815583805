import styled from "@emotion/styled";
import { useUserAssociatedTokenAccounts } from "@quarryprotocol/react-quarry";
import { RedeemerSDK } from "@saber-hq/redeemer-sdk";
import { useSail } from "@saberhq/sail";
import { TransactionEnvelope } from "@saberhq/solana-contrib";
import {
  getOrCreateATA,
  getOrCreateATAs,
  TokenAmount,
} from "@saberhq/token-utils";
import { useSolana } from "@saberhq/use-solana";
import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import type { TransactionInstruction } from "@solana/web3.js";
import { PublicKey } from "@solana/web3.js";
import { SBR_ADDRESS } from "@sunnyaggregator/sunny-quarry-sdk";
import {
  AIRDROP_IOU_MINT,
  AIRDROP_MERKLE_DISTRIBUTOR_KEY,
  CREATOR_KEY,
  SUNNY_ADDRESS,
} from "@sunnyaggregator/sunny-sdk";
import { useCallback, useMemo } from "react";
import invariant from "tiny-invariant";

import { useSunnyStake } from "../../../contexts/pool/useSunnyStake";
import type { UseSaberAirdropResult } from "../../../contexts/saberAirdrop";
import { useSDK } from "../../../contexts/sdk";
import type { LegacySunnyPool } from "../../../contexts/sunnyPools";
import type { SunnyPool } from "../../../contexts/sunnyPools/useAllSunnyPools";
import { breakpoints } from "../../../theme/breakpoints";
import { SABER_IOU_MINT, SUNNY_IOU_MINT } from "../../../utils/constants";
import { formatDisplayWithSoftLimit } from "../../../utils/format";
import { notify } from "../../../utils/notifications";
import { AsyncButton } from "../../common/AsyncButton";

interface MigrationItemProps {
  legacyPlot: LegacySunnyPool;
  sunnyPool: SunnyPool | undefined;
}

export const MigrationItem: React.FC<MigrationItemProps> = ({
  legacyPlot,
  sunnyPool,
}: MigrationItemProps) => {
  const stakedAmount = useMemo(() => {
    return legacyPlot.vault
      ? new TokenAmount(
          legacyPlot.pool.lpToken,
          legacyPlot.vault.accountInfo.data.stakedBalance
        )
      : null;
  }, [legacyPlot.pool.lpToken, legacyPlot.vault]);

  const [lpATA] = useUserAssociatedTokenAccounts([legacyPlot.pool.lpToken]);

  const hasOldDeposit = stakedAmount && stakedAmount.greaterThan(0);

  const { handleTXs } = useSail();
  const { sunnyMut } = useSDK();
  const { providerMut } = useSolana();
  const { createCreateVaultTX, createStakeTX } = useSunnyStake();

  const migrateHandler = useCallback(async () => {
    invariant(sunnyMut, "sdk not connected");
    invariant(providerMut, "provider mut");
    const { instructions } = await getOrCreateATAs({
      provider: providerMut,
      mints: {
        sbr: SBR_ADDRESS,
        sunny: SUNNY_ADDRESS,
        sbrIOU: SABER_IOU_MINT,
        sunnyIOU: SUNNY_IOU_MINT,
      },
    });

    const firstBatchTXs: TransactionEnvelope[] = [];
    const firstBatchOperations: string[] = [];

    const createRewardsATAsTX =
      instructions.length > 0
        ? new TransactionEnvelope(providerMut, instructions.slice())
        : null;
    if (createRewardsATAsTX) {
      firstBatchTXs.push(createRewardsATAsTX);
      firstBatchOperations.push("Create ATAs");
    }

    const pool = await sunnyMut.ssFarm.findPoolAddress({
      creator: CREATOR_KEY,
      lpMint: legacyPlot.pool.lpToken.mintAccount,
    });
    const vault = await sunnyMut.ssFarm.findVaultAddress({
      pool,
    });

    // if vault does not exist, then there is definitely nothing to claim
    if (!(await sunnyMut.provider.getAccountInfo(vault))) {
      return;
    }

    const createVaultTX = sunnyPool ? await createCreateVaultTX() : null;
    if (createVaultTX) {
      firstBatchTXs.push(createVaultTX);
      firstBatchOperations.push("Create Vault");
    }

    // Withdraw has to happen before claim or else there will be dust left over
    if (hasOldDeposit) {
      const theVault = await sunnyMut.ssFarm.loadVault({ vaultKey: vault });
      // Withdraw from old legacy pool
      firstBatchTXs.push(await theVault.withdraw(stakedAmount));
      firstBatchOperations.push("Withdraw");
    }

    if (firstBatchTXs.length > 0) {
      const firstTxResponse = await handleTXs(
        firstBatchTXs,
        firstBatchOperations.join(" + ")
      );
      await Promise.all(
        firstTxResponse.pending.map((pending) =>
          pending.wait({ commitment: "confirmed" })
        )
      );
      if (!firstTxResponse.success) {
        console.log(firstTxResponse.errors);
        const errorMessageDetails =
          firstTxResponse.errors !== undefined && firstTxResponse.errors[0]
            ? " " + firstTxResponse.errors[0].message
            : "";

        const err = new Error(
          `Error preparing migration.${errorMessageDetails}`
        );
        err.name = firstBatchOperations.join(" + ");
        throw err;
      }
    }

    const secondBatchTXs: TransactionEnvelope[] = [];
    const secondBatchOperations: string[] = [];

    // Claim all rewards
    const theVault = await sunnyMut.ssFarm.loadVault({ vaultKey: vault });
    secondBatchTXs.push(await theVault.claimMineRewards());
    secondBatchTXs.push(await theVault.claimFarmRewards());
    secondBatchOperations.push("Claim");

    if (
      hasOldDeposit &&
      sunnyPool !== undefined &&
      sunnyPool.status !== "hidden"
    ) {
      // If there is no corresponding new pool, the user can only withdraw
      // Deposit into new quarry
      let depositAmount = stakedAmount;
      if (lpATA?.balance.greaterThan("0")) {
        depositAmount = depositAmount.add(lpATA.balance);
      }
      secondBatchTXs.push(await createStakeTX(depositAmount));
      secondBatchOperations.push("Deposit");
    }

    if (secondBatchTXs.length > 0) {
      const secondTxResponse = await handleTXs(
        secondBatchTXs,
        secondBatchOperations.join(" + ")
      );
      if (!secondTxResponse.success) {
        console.log(secondTxResponse);
        secondTxResponse.errors;
        const errorMessageDetails =
          secondTxResponse.errors && secondTxResponse.errors[0]
            ? " " + secondTxResponse.errors[0].message
            : "";

        const err = new Error(
          `Error preparing migration.${errorMessageDetails}`
        );
        err.name = secondBatchOperations.join(" + ");
        throw err;
      }

      await Promise.all(
        secondTxResponse.pending.map((pending) =>
          pending.wait({ commitment: "finalized" })
        )
      );
    } else {
      throw new Error("No migration transactions");
    }
  }, [
    createCreateVaultTX,
    createStakeTX,
    handleTXs,
    hasOldDeposit,
    legacyPlot.pool.lpToken.mintAccount,
    lpATA?.balance,
    providerMut,
    stakedAmount,
    sunnyMut,
    sunnyPool,
  ]);

  return (
    <MigrationItemContainer>
      <PoolName>
        <span>{legacyPlot.pool.name}</span>
      </PoolName>
      <AsyncButton size="small" onClick={migrateHandler}>
        {sunnyPool === undefined || sunnyPool.status === "hidden"
          ? "Withdraw Only"
          : "Migrate"}
      </AsyncButton>
    </MigrationItemContainer>
  );
};

interface SaberAirdropItemProps {
  saberAirdropResult: UseSaberAirdropResult;
}

export const SaberAirdropItem: React.FC<SaberAirdropItemProps> = (
  props: SaberAirdropItemProps
) => {
  const sar = props.saberAirdropResult;
  const { providerMut } = useSolana();
  const { handleTX } = useSail();

  const { sunnyMut } = useSDK();

  const claimAirdropHandler = async () => {
    invariant(providerMut, "providerMut not found");
    invariant(sunnyMut, "sunnyMut not found");

    let totalStepCount = sar.airdropResponses.length * 2 + 1;
    let hadAnyError = false;

    let stepN = 1;
    const setupATAInstructions = [];
    const { address: sbrATAAddress, instruction: createSBRATAIx } =
      await getOrCreateATA({
        provider: providerMut,
        mint: SBR_ADDRESS,
        owner: providerMut.wallet.publicKey,
      });
    if (createSBRATAIx) {
      setupATAInstructions.push(createSBRATAIx);
    }

    const { instruction: setupIouATAIx, address: ownerIouATAAddress } =
      await getOrCreateATA({
        provider: providerMut,
        mint: AIRDROP_IOU_MINT,
        owner: providerMut.wallet.publicKey,
      });

    if (setupIouATAIx) {
      setupATAInstructions.push(setupIouATAIx);
    }
    if (setupATAInstructions.length) {
      totalStepCount += 1;
    }

    notify({
      message: `Claiming your airdrop will require up to ${totalStepCount} transactions.`,
      description: `Approve airdrop claim transactions`,
      type: "info",
    });
    if (setupATAInstructions.length) {
      const sbrATATx = await handleTX(
        new TransactionEnvelope(providerMut, setupATAInstructions),
        `Create SBR Associated Token Account (${stepN}/${totalStepCount})`
      );

      await sbrATATx.pending?.wait({ commitment: "confirmed" });
      if (!sbrATATx.success) {
        let message = "Unable to create ATAs.";
        if (sbrATATx.errors && sbrATATx.errors[0]) {
          message = sbrATATx.errors[0].message;
        }
        hadAnyError = true;

        notify({
          message: message,
          description: `Airdrop claim incomplete: Create IOU ATA (${stepN}/${totalStepCount})`,
          type: "error",
        });
        return;
      }
    }

    let lastResponse;
    for (const airdrop of sar.airdropResponses) {
      const theVault = await sunnyMut.ssFarm.loadVault({
        vaultKey: airdrop.vaultKey,
      });

      const ataInstructions: TransactionInstruction[] = [];
      const { instruction: instruction1, address: vaultIouATA } =
        await getOrCreateATA({
          provider: providerMut,
          mint: AIRDROP_IOU_MINT,
          owner: airdrop.vaultKey,
        });

      const { instruction: instruction3, address: poolIouATAAddress } =
        await getOrCreateATA({
          provider: providerMut,
          mint: AIRDROP_IOU_MINT,
          owner: airdrop.plot.sunnyPool.accountId,
        });

      if (instruction1) {
        ataInstructions.push(instruction1);
      }
      if (instruction3) {
        ataInstructions.push(instruction3);
      }

      if (ataInstructions.length) {
        const ataIxResponse = await handleTX(
          new TransactionEnvelope(providerMut, ataInstructions.slice()),
          `Create Airdrop IOU Token Accounts (${stepN}/${totalStepCount})`
        );

        await ataIxResponse.pending?.wait({ commitment: "confirmed" });
        if (!ataIxResponse.success) {
          let message = "Unable to create ATAs for vault";
          if (ataIxResponse.errors && ataIxResponse.errors[0]) {
            message = ataIxResponse.errors[0].message;
          }
          hadAnyError = true;

          notify({
            message: message,
            description: `Airdrop claim incomplete: Create Vault ATA (${stepN}/${totalStepCount})`,
            type: "error",
          });
          return;
        }
      }
      stepN++;

      const claimIx = await theVault.claimSaberAirdrop(
        airdrop.index,
        airdrop.amount,
        airdrop.proof.map((elt) => Buffer.from(elt, "hex")),
        AIRDROP_MERKLE_DISTRIBUTOR_KEY,
        AIRDROP_IOU_MINT,
        ownerIouATAAddress,
        vaultIouATA,
        poolIouATAAddress
      );

      lastResponse = await handleTX(
        claimIx,
        `Create Airdrop IOU Token Accounts (${stepN}/${totalStepCount})`
      );
      stepN++;

      if (!lastResponse.success) {
        hadAnyError = true;
        const errorMessageDetails =
          lastResponse.errors !== undefined && lastResponse.errors[0]
            ? lastResponse.errors[0].message
            : "";
        notify({
          message: errorMessageDetails,
          description: `Airdrop claim incomplete: Claim Airdrop For ${airdrop.plot.pool.name} (${stepN}/${totalStepCount})`,
          type: "error",
        });
      }
    }

    if (lastResponse) {
      await lastResponse.pending?.wait({ commitment: "confirmed" });
    }

    const sbrRedeemerSDK = RedeemerSDK.load({
      provider: providerMut,
    });
    const sbrRedeemer = await sbrRedeemerSDK.loadRedeemer({
      iouMint: AIRDROP_IOU_MINT,
      redemptionMint: new PublicKey(SBR_ADDRESS),
    });

    const redeemAllTX = new TransactionEnvelope(providerMut, [
      await sbrRedeemer.redeemAllTokensFromMintProxyIx({
        iouSource: ownerIouATAAddress,
        redemptionDestination: sbrATAAddress,
        sourceAuthority: providerMut.wallet.publicKey,
      }),
    ]);

    const redeemTx = await handleTX(
      redeemAllTX,
      `Redeem Saber Airdrop IOU Tokens Into SBR (${stepN}/${totalStepCount})`
    );

    if (!redeemTx.success) {
      hadAnyError = true;
      const errorMessageDetails =
        redeemTx.errors !== undefined && redeemTx.errors[0]
          ? redeemTx.errors[0].message
          : "";
      notify({
        message: errorMessageDetails,
        description: `Airdrop claim incomplete: Redeem Airdrop IOU Into SBR (${stepN}/${totalStepCount})`,
        type: "error",
      });
      return;
    }
    stepN++;

    await redeemTx.pending?.wait({ commitment: "confirmed" });

    if (!hadAnyError) {
      sar.claimFinishedCallback(providerMut.wallet.publicKey);
    }

    const closeATATx = new TransactionEnvelope(providerMut, [
      Token.createCloseAccountInstruction(
        TOKEN_PROGRAM_ID,
        ownerIouATAAddress,
        providerMut.wallet.publicKey,
        providerMut.wallet.publicKey,
        []
      ),
    ]);

    const closeResponse = await handleTX(closeATATx, `Close IOU Token Account`);

    if (!closeResponse.success) {
      const errorMessageDetails =
        closeResponse.errors !== undefined && closeResponse.errors[0]
          ? closeResponse.errors[0].message
          : "";
      notify({
        message: errorMessageDetails,
        description: "Unable to close token account",
        type: "error",
      });
      return;
    }
  };

  return (
    <MigrationItemContainer>
      <PoolName>
        <span>
          {formatDisplayWithSoftLimit(sar.totalAirdropAmount, 6, 18)} SBR
        </span>
      </PoolName>
      <AsyncButton size="small" onClick={claimAirdropHandler}>
        Claim Airdrop
      </AsyncButton>
    </MigrationItemContainer>
  );
};

const PoolName = styled.span`
  text-align: center;
  flex-grow: 1;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  line-height: 1.3;
  font-weight: 600;
  span {
    margin: 0 auto;
  }
  ${breakpoints.mobile} {
    flex-grow: 1;
  }
`;

const MigrationItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 4px;
  background: #f2f2f2;
  border-radius: 8px;
  margin-bottom: 6px;
  font-size: 15px;
  min-height: 44px;

  button {
    height: auto;
    padding: 6px 20px;
    flex: 50% 0 1;
    flex-shrink: 1;
    font-size: 15px;

    ${breakpoints.mobile} {
      flex: auto 0 1;
    }
  }
`;
