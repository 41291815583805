import { TokenAmount } from "@saberhq/token-utils";
import { useMemo } from "react";
import { createContainer } from "unstated-next";

import { formatCurrencySmart } from "../../utils/format";
import { useRouter } from "../router";
import type { SunnyPool } from "../sunnyPools/useAllSunnyPools";

const usePoolInternal = (pool?: SunnyPool) => {
  if (!pool) {
    throw new Error("Pool not specified");
  }
  const stakedAmount = useMemo(
    () =>
      pool.sunnyVault
        ? new TokenAmount(
            pool.saberPool.lpToken,
            pool.sunnyVault.accountInfo.data.internalBalance
          )
        : null,
    [pool.saberPool.lpToken, pool.sunnyVault]
  );

  const { saberTotalDeposits, sunnyTotalDeposits } = useMemo(() => {
    const saberTotalDeposits = new TokenAmount(
      pool.saberPool.lpToken,
      pool.underlyingQuarry.accountInfo.data.totalTokensDeposited
    );
    const totes = pool.sunnyPool.accountInfo.data.totalInternalBalance;
    const sunnyTotalDeposits = totes
      ? new TokenAmount(pool.saberPool.lpToken, totes)
      : totes;
    return { saberTotalDeposits, sunnyTotalDeposits };
  }, [pool.saberPool, pool.underlyingQuarry, pool.sunnyPool]);

  const { currency } = pool.saberPool;
  const { underlyingPerLPTokenMap } = useRouter();
  const tvl =
    underlyingPerLPTokenMap[pool.saberPool.id]?.multiply(sunnyTotalDeposits) ??
    null;
  const tvlStr = tvl && currency ? formatCurrencySmart(tvl, currency) : null;
  const tvlUSD = pool.poolValueLockedUSD;

  return {
    pool,
    stakedAmount,
    saberTotalDeposits,
    sunnyTotalDeposits,
    tvl,
    tvlStr,
    tvlUSD,
  };
};

export const { useContainer: usePool, Provider: PoolProvider } =
  createContainer(usePoolInternal);
