import styled from "@emotion/styled";

export const Footer: React.FC = () => {
  return (
    <Wrapper>
      <FooterLink href="https://sunny.ag/" target="_blank" rel="noreferrer">
        Return to sunny.ag
      </FooterLink>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  display: flex;
  gap: 12px 24px;
  padding: 40px 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const FooterLink = styled.a`
  color: #e29034;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;
