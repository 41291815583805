import { useRewarder } from "@quarryprotocol/react-quarry";
import type { HandleTXResponse } from "@saberhq/sail";
import { useSail } from "@saberhq/sail";
import type { TransactionEnvelope } from "@saberhq/solana-contrib";
import type { TokenAmount } from "@saberhq/token-utils";
import { useSolana } from "@saberhq/use-solana";
import * as Sentry from "@sentry/react";
import { SunnySDK } from "@sunnyaggregator/sunny-quarry-sdk";
import { useCallback } from "react";
import invariant from "tiny-invariant";

import { QUARRY_REWARDER_KEY } from "../../utils/constants";
import { handleException } from "../../utils/error";
import { usePool } from ".";

export const useSunnyStake = (): {
  createCreateVaultTX: () => Promise<TransactionEnvelope | null>;
  createStakeTX: (amount: TokenAmount) => Promise<TransactionEnvelope>;
  stake: (amount: TokenAmount) => Promise<HandleTXResponse>;
} => {
  const { providerMut } = useSolana();
  const { rewardToken } = useRewarder();
  const { pool } = usePool();
  const { handleTX } = useSail();

  const createCreateVaultTX = useCallback(async () => {
    invariant(rewardToken, "reward token");
    invariant(providerMut, "providerMut not found");
    const sdk = SunnySDK.load({
      provider: providerMut,
      environment: {
        rewarder: QUARRY_REWARDER_KEY,
      },
    });
    const { sunnyVault, sunnyPool } = pool;
    if (!sunnyVault) {
      const newVault = await sdk.spQuarry.newVault({
        pool: {
          key: sunnyPool.accountId,
          data: sunnyPool.accountInfo.data,
        },
        rewardsMint: rewardToken.mintAccount,
      });
      return newVault.tx;
    }
    return null;
  }, [pool, providerMut, rewardToken]);

  const createStakeTX = useCallback(
    async (amount: TokenAmount) => {
      invariant(rewardToken, "reward token");
      invariant(providerMut, "providerMut not found");
      const sdk = SunnySDK.load({
        provider: providerMut,
        environment: {
          rewarder: QUARRY_REWARDER_KEY,
        },
      });
      const { sunnyPool } = pool;

      let step = 0;
      try {
        step = 2;
        const vaultKey = await sdk.spQuarry.findVaultAddress({
          owner: sdk.provider.wallet.publicKey,
          pool: sunnyPool.accountId,
        });
        step = 3;
        const theVault = await sdk.spQuarry.loadVault({ vaultKey });

        step = 4;
        const depositTX = await theVault.deposit(amount);
        step = 5;

        return depositTX;
      } catch (e) {
        console.error("stake error at step", step, e);
        Sentry.captureException(e, {
          extra: {
            step,
          },
        });
        throw e;
      }
    },
    [pool, providerMut, rewardToken]
  );

  const stake = useCallback(
    async (amount: TokenAmount) => {
      const newVault = await createCreateVaultTX();
      if (newVault) {
        // TODO(surya): this should be done atomically with the deposit step
        const createVaultTXResponse = await handleTX(newVault, "Create Vault");
        if (!createVaultTXResponse.success || !createVaultTXResponse.pending) {
          if (createVaultTXResponse.errors && createVaultTXResponse.errors[0]) {
            throw new Error(
              "Error creating vault. " + createVaultTXResponse.errors[0].message
            );
          }
          throw new Error("Error creating vault");
        }
        await createVaultTXResponse.pending.wait({ commitment: "confirmed" });
      }

      const stakeTX = await createStakeTX(amount);
      try {
        return await handleTX(stakeTX, `Stake ${amount.formatUnits()}`);
      } catch (e) {
        handleException(e, {
          userMessage: {
            title: "Error staking into pool",
          },
        });
        throw e;
      }
    },
    [createCreateVaultTX, createStakeTX, handleTX]
  );

  return { createCreateVaultTX, createStakeTX, stake };
};
