import { Coder } from "@project-serum/anchor";
import type { AccountParser } from "@saberhq/sail";
import type { KeyedAccountInfo } from "@solana/web3.js";
import type { Farmer, Landlord, Plot } from "@sunnyaggregator/sunny-sdk";
import { FarmJSON } from "@sunnyaggregator/sunny-sdk";

const farmCoder = new Coder(FarmJSON);

export const PARSE_LANDLORD: AccountParser<Landlord> = (d: KeyedAccountInfo) =>
  farmCoder.accounts.decode<Landlord>("Landlord", d.accountInfo.data);

export const PARSE_PLOT: AccountParser<Plot> = (d: KeyedAccountInfo) =>
  farmCoder.accounts.decode<Plot>("Plot", d.accountInfo.data);

export const PARSE_FARMER: AccountParser<Farmer> = (d: KeyedAccountInfo) =>
  farmCoder.accounts.decode<Farmer>("Farmer", d.accountInfo.data);
