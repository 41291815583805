import type {
  IExchange,
  IExchangeInfo,
  StableSwap,
} from "@saberhq/stableswap-sdk";
import { Fraction } from "@saberhq/token-utils";
import { useCallback, useMemo } from "react";
import { createContainer } from "unstated-next";

import { getMarket } from "../utils/currencies";
import { useAllPools } from "../utils/exchange/useAllPools";
import type { Pool } from "../utils/useEnvironment";
import { usePrices } from "./prices";

export type ExchangeMap = { [lpToken: string]: ExchangeInfo };

interface Router {
  loading: boolean;
  exchangeMap: ExchangeMap;
  getExchangeInfo: (exchange: IExchange) => ExchangeInfo | null;

  /**
   * Number of underlying tokens each LP token represents.
   */
  underlyingPerLPTokenMap: Record<string, Fraction>;
  usdPerLPTokenMap: Record<string, Fraction>;
}

interface ExchangeInfo {
  exchange: IExchange & Pool;
  swap: StableSwap;
  info: IExchangeInfo;
  virtualPrice: Fraction | null;
}

const useRouterInternal = (): Router => {
  const { poolsMap, loading } = useAllPools();
  const exchangeMap = poolsMap;
  const getExchangeInfo = useCallback(
    (exchange: IExchange): ExchangeInfo | null => {
      const exchangeConfig = exchange
        ? exchangeMap[exchange.lpToken.mintAccount.toString()]
        : null;
      return exchangeConfig ?? null;
    },
    [exchangeMap]
  );

  const { prices } = usePrices();

  const { underlyingPerLPTokenMap, usdPerLPTokenMap } = useMemo(() => {
    const underlyingPerLPTokenMap: Record<string, Fraction> = {};
    const usdPerLPTokenMap: Record<string, Fraction> = {};
    Object.values(exchangeMap).forEach((pool) => {
      const currency = getMarket(pool.exchange.tokens[0]);
      const { price: currencyPrice } = prices[currency];
      const lpTotalSupply = pool.info.lpTotalSupply;
      const totalUnderlying = pool.info.reserves
        .map((r) => r.amount.asFraction)
        .reduce((acc, el) => acc.add(el));
      const tvlUSD =
        (currencyPrice && totalUnderlying.multiply(currencyPrice)) || null;
      if (tvlUSD) {
        const usdPerLPToken = tvlUSD.divide(lpTotalSupply);
        usdPerLPTokenMap[pool.exchange.id] = usdPerLPToken;
      }
      underlyingPerLPTokenMap[pool.exchange.id] =
        totalUnderlying.divide(lpTotalSupply);
    }, new Fraction(0));
    return { underlyingPerLPTokenMap, usdPerLPTokenMap };
  }, [exchangeMap, prices]);

  return {
    loading,
    exchangeMap,
    getExchangeInfo,

    underlyingPerLPTokenMap,
    usdPerLPTokenMap,
  };
};

export const { useContainer: useRouter, Provider: RouterProvider } =
  createContainer(useRouterInternal);
