import {
  QuarrySDKProvider,
  RewarderProvider,
} from "@quarryprotocol/react-quarry";
import React from "react";

import { QUARRY_REWARDER_KEY } from "../utils/constants";
import { useEnvironment } from "../utils/useEnvironment";

interface Props {
  children?: React.ReactNode;
}

export const SunnyQuarryProvider: React.FC<Props> = ({ children }: Props) => {
  const { tokenMap } = useEnvironment();
  return (
    <QuarrySDKProvider initialState={{ tokenMap: tokenMap ?? undefined }}>
      <RewarderProvider initialState={{ rewarderKey: QUARRY_REWARDER_KEY }}>
        {children}
      </RewarderProvider>
    </QuarrySDKProvider>
  );
};
