import type { Network } from "@saberhq/solana-contrib";

export const poolIDs = [
  "usdc_usdt",
  "usdc_pai",
  "btc",
  "pbtc",
  "ust",
  "dai",
  "busd",
  "luna",
  "frax",
  "hbtc",
  "husd",
  "usdk",
  "ftt",
  "srm",
  "ibbtc",
  "ibbtc_ren",
  "webusd",
  "weusdc",
  "weusdt",
  "wusdk",
  "whusd",
  "wdai",
  "wfrax",
  "wheth",
  "acusd",
  "apusdt",
  "apusdc",
  "mai",
  "abbusd",
  "aeeth",
  "msol_sol",
  "psol",
  "prtsol",
  "stsol",
  "socean",
] as const;
export type PoolID = typeof poolIDs[number];

export type IEnvironment = Readonly<{
  name: string;
  endpoint: string;
}>;

export type IExchangeBasic = {
  /**
   * Name of the pool to use in the pool list.
   */
  name?: string;
  swapAccount: string;
  lpToken: string;
  tokenA: string;
  tokenB: string;
  devOnly?: boolean;
  swapIcons?: boolean;
};

export const environments = {
  "mainnet-beta": {
    name: "Mainnet Beta",
    endpoint: "https://b0dc6b32.rpcpool.com/",
  },
  devnet: {
    name: "Devnet",
    endpoint: "https://api.devnet.solana.com/",
  },
  testnet: {
    name: "Testnet",
    endpoint: "https://api.testnet.solana.com/",
  },
  localnet: {
    name: "Localnet",
    endpoint: "http://localhost:8899/",
  },
} as const;

export const getEnvironment = (network: Network): IEnvironment =>
  environments[network];
