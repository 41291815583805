/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ExtendedSecp256k1Signature,
  ripemd160,
  Secp256k1,
  sha256,
} from "@cosmjs/crypto";
import type { StdSignDoc } from "@cosmjs/launchpad";
import { serializeSignDoc } from "@cosmjs/launchpad";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Keplr, Window as KeplrWindow } from "@keplr-wallet/types";
import { useNativeAccount, useSail } from "@saberhq/sail";
import { TransactionEnvelope } from "@saberhq/solana-contrib";
import { Fraction, getOrCreateATA, u64 } from "@saberhq/token-utils";
import { useConnectedWallet, useSolana } from "@saberhq/use-solana";
import { Keypair } from "@solana/web3.js";
import {
  coins,
  SigningStargateClient,
} from "@sunnyaggregator/modified-cosmjs-stargate";
import { MerkleDistributorSDK } from "@sunnyaggregator/osmosis-merkle-distributor";
import { osmosisAddressToBuffer } from "@sunnyaggregator/osmosis-merkle-distributor/dist/utils";
import { bech32 } from "bech32";
import { useCallback, useEffect, useState } from "react";
import invariant from "tiny-invariant";

import type { AirdropResponse } from "../../../contexts/osmosisAirdrop";
import {
  useDistributorAccountExists,
  useOsmosisAirdrop,
} from "../../../contexts/osmosisAirdrop";
import { breakpoints } from "../../../theme/breakpoints";
import {
  OSMOSIS_AIRDROP_MERKLE_DISTRIBUTOR_KEY,
  OSMOSIS_AIRDROP_MINT,
} from "../../../utils/constants";
import { notify } from "../../../utils/notifications";
import { AsyncButton } from "../../common/AsyncButton";
import { Button } from "../../common/Button";
import { Digits } from "../../common/Digits";
import { SlowRotatingSunLogo } from "../../common/LoadingSpinner";
import { OsmosisAnimatedIcon } from "./OsmosisAnimatedIcon";

type UnboxPromise<T extends Promise<unknown>> = T extends Promise<infer U>
  ? U
  : never;
declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends KeplrWindow {}
}

// From @cosmjs/proto-signing
/**
 * This is the same as Algo from @cosmjs/launchpad but those might diverge in the future.
 */
export declare type Algo = "secp256k1" | "ed25519" | "sr25519";
/**
 * This is the same as AccountData from @cosmjs/launchpad but those might diverge in the future.
 */
export interface AccountData {
  /** A printable address (typically bech32 encoded) */
  readonly address: string;
  readonly algo: Algo;
  readonly pubkey: Uint8Array;
}

// https://docs.keplr.app/api/
const getKeplr = async (): Promise<Keplr | undefined> => {
  if (window.keplr) {
    return window.keplr;
  }

  if (document.readyState === "complete") {
    return window.keplr;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event: Event) => {
      if (
        event.target &&
        (event.target as Document).readyState === "complete"
      ) {
        resolve(window.keplr);
        document.removeEventListener("readystatechange", documentStateChange);
      }
    };

    document.addEventListener("readystatechange", documentStateChange);
  });
};

export const OsmosisAirdropWidget: React.FC = () => {
  const wallet = useConnectedWallet();
  const { nativeBalance } = useNativeAccount();

  const { providerMut } = useSolana();
  const { handleTX } = useSail();

  const distributorAccountExists = useDistributorAccountExists(
    OSMOSIS_AIRDROP_MERKLE_DISTRIBUTOR_KEY
  );

  // undefined: loading
  // null: no keplr found
  // Keplr: ... duh keplr
  const [keplr, setKeplr] = useState<Keplr | undefined | null>(undefined);
  const [osmosisAccount, setOsmosisAccount] = useState<AccountData | undefined>(
    undefined
  );

  const [signingClient, setSigningClient] = useState<
    SigningStargateClient | undefined
  >(undefined);

  useEffect(() => {
    getKeplr()
      .then((gotKeplr) => {
        if (gotKeplr === undefined) {
          setKeplr(null);
        } else {
          setKeplr(gotKeplr);
        }
      })
      .catch(() => {
        if (keplr === undefined) {
          setKeplr(keplr);
        }
        return;
      })
      .finally(() => {
        return;
      });
  }, [keplr]);

  const osmosisAirdropResult = useOsmosisAirdrop(osmosisAccount);

  const signUntilWorking = useCallback(
    async (
      signingClient: SigningStargateClient,
      osmosisAccount: AccountData
    ) => {
      let requested = false;
      let rejected = false;

      if (!wallet) {
        throw new Error("wallet null");
      }
      for (let i = 0; i < 1000; i++) {
        const messages = [
          {
            value: {
              fromAddress: osmosisAccount.address,
              toAddress: osmosisAccount.address,
              amount: coins(0, "uosmo"),
            },
            typeUrl: "/cosmos.bank.v1beta1.MsgSend",
          },
        ];

        const fee = { gas: "100000", amount: coins(0, "uosmo") };
        const memo =
          "Claim SUNNY airdrop (DO NOT EDIT MEMO): " +
          wallet.publicKey.toBase58() +
          " " +
          Math.floor(Math.random() * (999999 - 100000) + 100000).toString();
        const explicitSignerData = {
          accountNumber: 0,
          sequence: 0,
          chainId: "osmosis-1",
        };

        await new Promise((resolve) => setTimeout(resolve, 200));

        try {
          if (rejected) {
            console.log("Rejected. Lets leave");
            break;
          } else if (requested) {
            console.log("Already requested and waiting");
          } else {
            requested = true;
            const result = await signingClient
              .sign(
                osmosisAccount.address,
                messages,
                fee,
                memo,
                explicitSignerData
              )
              .then((txRaw) => {
                requested = false;
                // console.log(txRaw);

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const signDoc: StdSignDoc = window.signDoc;

                const serializedSignDoc = serializeSignDoc(signDoc);
                // const signBytes = makeSignBytes(window.signDoc);

                // console.log("signBytes", signBytes);

                // const actualSignBytes =
                //   signBytes.length === 0 ? serializedSignDoc : signBytes;

                // console.log(
                //   `{
                //     actualSignBytes: new Uint8Array([
                //     ${serializedSignDoc.join(",")}
                //   ]),
                //   signatures: [
                //     new Uint8Array([
                //       ${txRaw.signatures[0]!!.join(",")}
                //     ]),
                //   ],
                // };`
                // );

                const sigWith1AtTheEnd = new Uint8Array(65);

                sigWith1AtTheEnd.set(txRaw.signatures[0]!!);
                sigWith1AtTheEnd.set([1], 64);
                // console.log("sigWith1AtTheEnd", sigWith1AtTheEnd);
                // const sig = SAMPLE_1_PROOF.signatures[0]!!;
                const extendedSignature =
                  ExtendedSecp256k1Signature.fromFixedLength(sigWith1AtTheEnd);

                // console.log("extendedSignature", extendedSignature);

                const hashedSignBytes = sha256(serializedSignDoc);
                // console.log("sha256(actualSignBytes)", hashedSignBytes);

                const recoveryResult = Secp256k1.recoverPubkey(
                  extendedSignature,
                  hashedSignBytes
                );
                // console.log("Recoveryresult", recoveryResult);

                // we want osmosis public key [2, 173, 31, 132, 76, 6, 176, 165, 143, 83, 144, 42, 247, 103, 94, 129, 159, 189, 26, 47, 2, 192, 88, 72, 157, 201, 229, 26, 69, 153, 73, 11, 79]

                const recoveryCompressed = Secp256k1.compressPubkey(
                  new Uint8Array(recoveryResult)
                );
                // console.log("Recovery (compressed))", recoveryCompressed);

                const recoveredAddress = bech32.encode(
                  "osmo",
                  bech32.toWords(ripemd160(sha256(recoveryCompressed)))
                );

                if (recoveredAddress === osmosisAccount.address) {
                  console.log("actualSignBytes:", serializedSignDoc.join(","));
                  console.log("signatures:", txRaw.signatures[0]!!.join(","));
                  return {
                    noError: true,
                    success: true,
                    finalResult: {
                      actualSignBytes: serializedSignDoc,
                      signatures: txRaw.signatures,
                    },
                  };
                } else {
                  console.log(
                    "Recovered address did not match: ",
                    recoveredAddress
                  );

                  return {
                    noError: true,
                    success: false,
                  };
                }
              })
              .catch((e: Error) => {
                console.log(e);
                requested = false;

                rejected = true;

                console.error(e);
                if (e instanceof Error) {
                  notify({
                    type: "error",
                    message: "Unknown error",
                    description: e.message,
                  });
                }

                return "error";
              });

            // console.log("Attempt", actualAttempts);
            // console.log(result);

            if (result === "error") {
              return;
            }

            if (rejected) {
              return;
            }
            if (
              typeof result !== "string" &&
              result.noError === true &&
              result.success
            ) {
              return result.finalResult;
            }
          }
        } catch (e) {
          console.error("Unexpected error", e);
        }
      }
    },
    [wallet]
  );

  const claimAirdropHandler = async ({
    proofOfOwnership,
    airdropResponse,
    airdropAmount,
  }: {
    proofOfOwnership: Exclude<
      UnboxPromise<ReturnType<typeof signUntilWorking>>,
      undefined
    >;
    airdropResponse: AirdropResponse;
    airdropAmount: number;
  }) => {
    invariant(providerMut, "providerMut not found");

    const { instruction: createSUNNYATAIx } = await getOrCreateATA({
      provider: providerMut,
      mint: OSMOSIS_AIRDROP_MINT,
      owner: providerMut.wallet.publicKey,
    });
    const stepN = 1;
    const totalStepCount = createSUNNYATAIx ? 3 : 2;
    if (createSUNNYATAIx) {
      notify({
        message: `Creating a token account to enable receiving SUNNY.`,
        description: `Setup Associated Token Account (${stepN}/${totalStepCount})`,
        type: "info",
      });

      const ataTx = await handleTX(
        new TransactionEnvelope(providerMut, [createSUNNYATAIx]),
        `Create Sunny Associated Token Account`
      );

      await ataTx.pending?.wait({ commitment: "confirmed" });
      if (!ataTx.success) {
        let message = "Unable to create ATAs.";
        if (ataTx.errors && ataTx.errors[0]) {
          message = ataTx.errors[0].message;
        }

        notify({
          message: message,
          description: `Airdrop claim incomplete: SUNNY ATA creation failed`,
          type: "error",
        });
        return;
      }
    }
    const sdk = MerkleDistributorSDK.load({ provider: providerMut });

    const distributorW = await sdk.loadDistributor(
      OSMOSIS_AIRDROP_MERKLE_DISTRIBUTOR_KEY
    );

    // notify({
    //   message: `Approve Claim (2 transactions)`,
    //   description: `The first transaction requires SOL to pay for rent deposit, and will be refunded in the next transaction.`,
    //   type: "info",
    // });

    const uploadProofTx = await distributorW.uploadProof({
      index: new u64(airdropResponse.index),
      proof: airdropResponse.proof.map((p) => Buffer.from(p, "hex")),
      claimant: providerMut.wallet.publicKey,
    });

    try {
      const uploadTxSimluate = uploadProofTx.tx.build();
      uploadTxSimluate.feePayer = providerMut.wallet.publicKey;
      const uploadSimResult = await providerMut.connection.simulateTransaction(
        uploadTxSimluate,
        undefined
      );
      console.log(
        "Simulation result",
        uploadSimResult.value,
        uploadSimResult.value.logs?.join("\n")
      );

      if (!uploadSimResult.value.err) {
        notify({
          message: `Upload proof`,
          description: `This first transaction requires SOL to pay a refundable Solana rent fee, and will be refunded after claiming.`,
          type: "info",
        });

        const uploadResult = await handleTX(uploadProofTx.tx, `Upload Proof`);

        await uploadResult.pending?.wait({ commitment: "confirmed" });
        if (!uploadResult.success) {
          let message = "Unknown error";
          if (uploadResult.errors && uploadResult.errors[0]) {
            message = uploadResult.errors[0].message;
          }

          notify({
            message: `Unable to upload proof. Will attempt to claim anyways.`,
            description: message,
            type: "error",
          });
        }
      }

      if (uploadSimResult.value.logs?.join("\n").includes("already in use")) {
        console.log("Proof account already in use");
      }
    } catch (e) {
      console.error(e);
    }

    const claimTx = await distributorW.claim({
      index: new u64(airdropResponse.index),
      amount: new u64(airdropResponse.amount),
      // Proof is not necessary, but we do a safety check
      proof: airdropResponse.proof.map((p) => Buffer.from(p, "hex")),
      claimant: providerMut.wallet.publicKey,
      osmosisAddress160: osmosisAddressToBuffer(airdropResponse.osmosisAddress),
      signBytes: proofOfOwnership.actualSignBytes,
      signatures: proofOfOwnership.signatures,
    });

    notify({
      message: `Claim Osmosis Airdrop`,
      description: `Claiming the airdrop of SUNNY!`,
      type: "info",
    });

    const claimResult = await handleTX(claimTx, `Claim Osmosis Airdrop`);

    await claimResult.pending?.wait({ commitment: "confirmed" });
    console.log(claimResult);
    if (!claimResult.success) {
      let message = "Unknown error";
      if (claimResult.errors && claimResult.errors[0]) {
        message = claimResult.errors[0].message;
      }

      notify({
        message: `Unable to claim airdrop`,
        description: message,
        type: "error",
      });
    } else {
      notify({
        message: `Congrats and thank you!`,
        description: `Airdrop of ${airdropAmount.toString()} SUNNY successfully claimed!`,
        type: "success",
      });
    }
  };

  const [proofOfOwnership, setProofOfOwnership] =
    useState<UnboxPromise<ReturnType<typeof signUntilWorking>>>(undefined);

  let resultBox: JSX.Element | undefined = undefined;
  const airdropResponse = osmosisAirdropResult.airdropResponse;
  if (!keplr) {
    resultBox = (
      <AirdropBox>Install Keplr to connect your Osmosis wallet.</AirdropBox>
    );
  } else if (signingClient === undefined || !osmosisAccount?.address) {
    resultBox = (
      <AirdropBox>
        Connect your Osmosis wallet to check airdrop amount.
      </AirdropBox>
    );
  } else if (osmosisAirdropResult.isLoading) {
    // User is connected to Osmosis wallet now

    resultBox = <AirdropBox>Loading airdrop status...</AirdropBox>;
  } else if (
    osmosisAirdropResult.airdropAmount === 0 ||
    airdropResponse === undefined
  ) {
    resultBox = (
      <AirdropBox>
        <AirdropBoxWarning>
          Your Osmosis account was not eligible for the SUNNY airdrop. For more
          info about how the airdrop amount was calculated, see the{" "}
          <a
            href="https://github.com/SunnyAggregator/osmosis-airdrop-calculations"
            target="_blank"
            rel="noreferrer"
          >
            airdrop calculation source code
          </a>
          .
        </AirdropBoxWarning>
      </AirdropBox>
    );
  } else {
    // User was eligible for something
    const number = (
      <RewardsNumbersWrapper>
        <RewardsNumbersReward>
          <SpacerWrapper>
            <AnimatedGaryShell>
              <AnimatedGaryShimmer />
              <GarysText>
                <Digits value={osmosisAirdropResult.airdropAmount} />
              </GarysText>
            </AnimatedGaryShell>{" "}
          </SpacerWrapper>
          <SlowRotatingSunLogo />
          <CoinName>SUNNY</CoinName>
        </RewardsNumbersReward>
      </RewardsNumbersWrapper>
    );

    if (osmosisAirdropResult.claimedAlready === true) {
      resultBox = (
        <AirdropBox>
          {number}
          <AirdropBoxWarning>
            You have claimed {osmosisAirdropResult.airdropAmount} SUNNY!
          </AirdropBoxWarning>
        </AirdropBox>
      );
    } else if (osmosisAirdropResult.claimedAlready) {
      resultBox = (
        <AirdropBox>
          {number}
          <AirdropBoxWarning>
            Your Osmosis airdrop of {osmosisAirdropResult.airdropAmount} SUNNY
            has already been claimed by Solana wallet{" "}
            <span
              css={css`
                word-break: break-all;
              `}
            >
              {osmosisAirdropResult.claimedAlready}
            </span>
            .
          </AirdropBoxWarning>
        </AirdropBox>
      );
    } else if (!wallet || nativeBalance === undefined) {
      resultBox = (
        <AirdropBox>{number}Connect your Solana wallet to claim.</AirdropBox>
      );
    } else if (nativeBalance.lessThan(new Fraction(26, 1000))) {
      resultBox = (
        <AirdropBox>
          {number}
          <AirdropBoxWarning>
            Fund your Solana wallet with at least 0.04 SOL to claim your SUNNY.
          </AirdropBoxWarning>
        </AirdropBox>
      );
    } else if (distributorAccountExists === false) {
      resultBox = (
        <AirdropBox>
          {number}
          <AirdropBoxWarning>
            You are eligible to claim your SUNNY. The airdrop is not yet ready
            to be claimed. Please return in a few days.
          </AirdropBoxWarning>
        </AirdropBox>
      );
    } else if (!proofOfOwnership) {
      resultBox = (
        <AirdropBox>
          {number}{" "}
          <Button
            size="small"
            onClick={() => {
              console.log("Sign button clicked");
              signUntilWorking(signingClient, osmosisAccount)
                .then((finalResult) => {
                  if (finalResult) {
                    console.log("Final result", finalResult);
                    setProofOfOwnership(finalResult);
                  }
                })
                .catch((e) => {
                  console.error(e);
                  if (e instanceof Error) {
                    notify({
                      type: "error",
                      message: "Unknown error",
                      description: e.message,
                    });
                  }
                  return;
                });
            }}
          >
            Sign proof of ownership
          </Button>
          <AirdropBoxWarning>
            Sign to prove that you own this address. This will not cost
            anything, and the transaction will not be broadcast to the Osmosis
            network. Due to an issue with Cosmos signing, you may be asked to
            sign multiple times.
          </AirdropBoxWarning>
        </AirdropBox>
      );
    } else {
      resultBox = (
        <AirdropBox>
          {number}{" "}
          <Button
            onClick={() => {
              claimAirdropHandler({
                proofOfOwnership,
                airdropResponse: airdropResponse,
                airdropAmount: osmosisAirdropResult.airdropAmount,
              })
                .then(() => {
                  return;
                })
                .catch((e) => {
                  console.error(e);
                  if (e instanceof Error) {
                    notify({
                      type: "error",
                      message: "Unknown error",
                      description: e.message,
                    });
                  }
                });
            }}
          >
            Claim {osmosisAirdropResult.airdropAmount} SUNNY
          </Button>
        </AirdropBox>
      );
    }
  }

  return (
    <AirdropWidgetContainer>
      <OsmosisAnimatedIcon />
      {window.location.hash === "#setup" && wallet ? (
        <DistributorSetupButton />
      ) : null}
      <h3>SUNNY airdrop to Osmosis users</h3>

      <p>
        Osmosis holders may be eligible for an airdrop of SUNNY. For more
        details,{" "}
        <a
          href="https://medium.com/sunny-aggregator/sunny-announces-airdrop-to-osmosis-osmo-holders-6e787c4502ac"
          target="_blank"
          rel="noreferrer"
        >
          read the airdrop announcement article
        </a>
        .{" "}
        <strong>
          The airdrop is not yet claimable, but you can view your airdrop amount
          here.
        </strong>
      </p>
      <p>To successfully claim, you will need:</p>
      <ol>
        <li>A Solana wallet with at least 0.04 SOL</li>
        <li>
          An eligible Osmosis wallet in{" "}
          <a
            href="https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap"
            target="_blank"
            rel="noreferrer"
          >
            Keplr Browser Extension
          </a>
        </li>
      </ol>
      <WalletConnector>
        <WalletConnectorLabel>Solana</WalletConnectorLabel>

        {wallet === null ? (
          <AsyncButton
            size="small"
            onClick={() => {
              return;
            }}
            connectWalletOverride={"Connect Solana Wallet"}
          ></AsyncButton>
        ) : (
          <WalletConnectorAddress>
            {wallet.publicKey.toBase58()}
          </WalletConnectorAddress>
        )}
      </WalletConnector>
      <WalletConnector>
        <WalletConnectorLabel>Osmosis</WalletConnectorLabel>

        {keplr ? (
          osmosisAccount ? (
            <WalletConnectorAddress>
              {osmosisAccount.address}
            </WalletConnectorAddress>
          ) : (
            <Button
              size="small"
              onClick={() => {
                const chainId = "osmosis-1";
                keplr
                  .enable(chainId)
                  .then(() => {
                    const offlineSigner =
                      keplr.getOfflineSignerOnlyAmino(chainId);

                    return offlineSigner.getAccounts().then((accounts) => {
                      if (accounts[0]) {
                        setOsmosisAccount(accounts[0]);

                        SigningStargateClient.offline(offlineSigner)
                          .then((client) => {
                            setSigningClient(client);
                          })
                          .catch((e) => {
                            console.error(e);
                          });
                      } else {
                        alert("No accounts found");
                      }
                    });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            >
              Connect Osmosis Wallet
            </Button>
          )
        ) : (
          <a
            href="https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              size="small"
              onClick={() => {
                return null;
              }}
            >
              Install Keplr for Chrome
            </Button>
          </a>
        )}
      </WalletConnector>
      {resultBox}
    </AirdropWidgetContainer>
  );
};

export const DistributorSetupButton = (): JSX.Element => {
  const { providerMut } = useSolana();
  const { handleTX } = useSail();

  const setupDistributor = useCallback(async () => {
    if (providerMut === null) {
      throw new Error("Provider is null");
    }
    console.log("Setting up distributor");
    const sdk = MerkleDistributorSDK.load({ provider: providerMut });

    // Total airdrop amount: 92363248492271
    // Total airdrop SUNNY: 92363248
    // Total recipients: 90722
    // Tree root: a5e328b22135a50afd71bb647e17480365b583a0f81492f66963bfdd23c4eee1

    const pendingDistributor = await sdk.createDistributor({
      root: Buffer.from(
        "a5e328b22135a50afd71bb647e17480365b583a0f81492f66963bfdd23c4eee1",
        "hex"
      ),
      maxTotalClaim: new u64(92363248492271),
      maxNumNodes: new u64(90722),
      tokenMint: OSMOSIS_AIRDROP_MINT,
      base: Keypair.fromSecretKey(
        new Uint8Array([
          152, 164, 243, 36, 74, 103, 122, 46, 52, 237, 57, 14, 17, 133, 190,
          93, 126, 198, 155, 41, 13, 105, 207, 133, 211, 93, 93, 212, 229, 131,
          249, 101, 151, 122, 98, 6, 44, 137, 103, 221, 196, 5, 89, 131, 132,
          57, 72, 20, 251, 68, 158, 230, 14, 115, 169, 180, 201, 129, 14, 117,
          56, 148, 187, 6,
        ])
      ),
    });

    const { tx } = pendingDistributor;

    console.log(
      "Creating distributor",
      pendingDistributor.distributor.toBase58()
    );
    const createDistributorResponse = await handleTX(tx);
    if (createDistributorResponse === null) {
      throw new Error("No response");
    }
    await createDistributorResponse.pending?.wait({ commitment: "confirmed" });
    createDistributorResponse.pending?.receipt?.printLogs();

    if (createDistributorResponse.success) {
      console.log("Distributor created");
    } else {
      console.error("Distributor creation failed");
      return;
    }

    const { distributor } = pendingDistributor;
    const distributorW = await sdk.loadDistributor(distributor);
    console.log(distributorW);
  }, [handleTX, providerMut]);
  return <button onClick={setupDistributor}>Set up distributor</button>;
};

const AirdropWidgetContainer = styled.div`
  background: #fff;
  color: #223;
  border-radius: 8px;
  max-width: 480px;
  margin: 0 auto 40px auto;
  padding: 24px 24px 18px 24px;

  position: relative;
  ${breakpoints.mobile} {
    padding: 24px 18px 18px 18px;
  }
  p {
    margin-bottom: 0.5em;
  }
  h3 {
    color: #223;
  }
`;

const AirdropBox = styled.div`
  padding-top: 8px;
  padding-bottom: 2px;
  button {
    margin: 0 auto;
    margin-bottom: 12px;
  }
`;

const AirdropBoxWarning = styled.div`
  border-radius: 8px;
  background: hsl(45, 100%, 88%);
  padding: 12px 20px;
  font-size: 15px;
  margin-bottom: 8px;
`;

const WalletConnector = styled.div`
  background: #f2f2f2;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;

  text-align: center;

  margin-bottom: 8px;
  button {
    margin: 0 auto;
  }
`;
const WalletConnectorLabel = styled.div`
  font-weight: bold;
`;
const WalletConnectorAddress = styled.div`
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 5px;
  word-break: break-all;
`;
const RewardsNumbersWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 16px;
`;
const RewardsNumbersReward = styled.div`
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  z-index: 4;
  img,
  svg {
    height: 22px;
    width: 22px;
    min-width: 22px;
    margin-right: 7px;
    color: #e29034;
  }
`;

// 🐌
const AnimatedGaryShell = styled.span`
  position: relative;
  background: #eee;
  border-radius: 8px;
  padding: 12px 16px;
  overflow: hidden;
  display: block;
  line-height: 1.2;
`;
const SpacerWrapper = styled.span`
  margin-right: 14px;
  ${breakpoints.mobile} {
    margin-right: 9px;
  }
`;
const AnimatedGaryShimmer = styled.span`
  position: absolute;
  left: -200px;
  right: 400px;
  top: 0;
  bottom: 0;
  width: 200px;
  background-image: linear-gradient(
    58deg,
    rgba(238, 238, 238, 0) 25%,
    rgba(255, 255, 255, 0.5) 46%,
    rgba(255, 255, 255, 0.5) 54%,
    rgba(238, 238, 238, 0) 75%
  );

  background-repeat: no-repeat;
  pointer-events: none;

  z-index: 0;
  animation: 1.8s linear 0s infinite running gary;
  @keyframes gary {
    from {
      transform: translateX(-200%);
      //   background-position: -100% 0px;
    }
    to {
      transform: translateX(200%);

      //   background-position: 100% 0px;
    }
  }
`;
const GarysText = styled.span`
  position: relative;
  z-index: 2;
  color: #bbb;
  font-weight: 600;
`;

const CoinName = styled.span`
  ${breakpoints.tinymobile} {
    font-size: 18px;
  }
`;
