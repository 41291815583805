import {
  useToken,
  useUserAssociatedTokenAccounts,
} from "@quarryprotocol/react-quarry";
import { findClaimStatusKey } from "@saberhq/merkle-distributor";
import { useAccountsData } from "@saberhq/sail";
import type { ConnectedWallet } from "@saberhq/use-solana";
import { u64 } from "@solana/spl-token";
import type { PublicKey } from "@solana/web3.js";
import {
  AIRDROP_IOU_MINT,
  AIRDROP_MERKLE_DISTRIBUTOR_KEY,
} from "@sunnyaggregator/sunny-sdk";
import { useEffect, useState } from "react";
import { useQueries } from "react-query";

import { notify } from "../../utils/notifications";
import type { LegacySunnyPool } from "../sunnyPools";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function responseIsValid(value: Record<string, any>): value is AirdropResponse {
  if (typeof value === "object" && value !== null) {
    const recordValue: Record<string, unknown> = value;
    if (typeof recordValue.index !== "number") {
      console.log("Invalid index");
      return false;
    }
    if (typeof recordValue.amount !== "string") {
      console.log("Invalid amount");
      return false;
    }
    if (!Array.isArray(recordValue.proof)) {
      console.log("Invalid proof");
      return false;
    }
    for (const proofElement of recordValue.proof) {
      if (typeof proofElement !== "string") {
        console.log("Invalid proof element");
        return false;
      }
    }
    return true;
  }

  return false;
}

const generateAirdropProofLink = (key: PublicKey): string =>
  `https://airdrop-artifacts.saber.so/proofs/${key.toString()}.json`;
const responseCache = new Map<string, AirdropResponse | null>();

// using react-query because swr sucks (it doesn't work with multiple items or at least i couldnt figure it out)
// actually it might work with multiple items, but it's trash
const vaultAirdropQueryFetcher = async (
  plot: LegacySunnyPool
): Promise<AirdropResponse | null> => {
  const vaultKey = plot.vault?.accountId;
  if (!vaultKey) return null;
  const url = generateAirdropProofLink(vaultKey);
  const cacheResult = responseCache.get(url);
  if (cacheResult) {
    return Promise.resolve({
      vaultKey: vaultKey,
      plot: plot,
      index: cacheResult.index,
      amount: cacheResult.amount,
      proof: cacheResult.proof,
    });
  } else if (cacheResult === null) {
    return null;
  }

  const response = await fetch(url);
  if (response.status === 200) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const responseJson = await response.json();
    if (responseIsValid(responseJson)) {
      responseCache.set(url, responseJson);
      return {
        vaultKey: vaultKey,
        plot: plot,
        index: responseJson.index,
        amount: responseJson.amount,
        proof: responseJson.proof,
      };
    }
    console.error("Invalid response: ", responseJson);
  }
  if (response.status === 404) {
    responseCache.set(url, null);
    return Promise.resolve(null);
  }

  console.log("Unhandled response type", response);
  return Promise.resolve(null);
};
// // Proof that i tried:
// function multiFetcher(...urls: string[]) {
//   return Promise.all(urls.map(reactQueryFetcher));
// }

// const { data } = useSWR(
//   vaultAddresses.map((vaultAddress) => {
//     return vaultAddress;
//   }),
//   multiFetcher
// );
// console.log(data);

export type AirdropResponse = {
  amount: string;
  index: number;
  proof: string[];
  vaultKey: PublicKey;
  plot: LegacySunnyPool;
};

export type UseSaberAirdropResult = {
  airdropResponses: AirdropResponse[];
  isLoading: boolean;
  totalAirdropAmount: number;
  containsActionableItems: boolean;
  claimFinishedCallback: (walletKey: PublicKey) => void;
};

type StatusKeyTuple = {
  vaultKey: PublicKey;
  statusKey: PublicKey;
};

let criticalErrorNotified = false;

export const useSaberAirdrop = (
  plots: LegacySunnyPool[],
  wallet: ConnectedWallet | null
): UseSaberAirdropResult => {
  const plotsWithVaultsAndMiners = plots.filter((plot) => {
    return (
      // has a vault
      plot.vault &&
      // has a miner
      plot.miner
    );
  });

  const sbrAirdropIOU = useToken(AIRDROP_IOU_MINT);

  const [sbrAirdropIOUATA] = useUserAssociatedTokenAccounts([
    sbrAirdropIOU ?? undefined,
  ]);

  const airdropQueryResults = useQueries(
    plotsWithVaultsAndMiners.map((plot) => {
      return {
        queryKey: [plot.index],
        queryFn: () => vaultAirdropQueryFetcher(plot),
      };
    })
  );

  const vaultKeysMemoKey = plotsWithVaultsAndMiners
    .map((plot) => plot.vault?.accountId.toString())
    .join("");

  const airdropResultsLoaded =
    plotsWithVaultsAndMiners.length === airdropQueryResults.length &&
    airdropQueryResults.every((airdropResult) => airdropResult.isFetched);

  const airdropResultInnerWithDrop: AirdropResponse[] = [];
  for (const airdropResultQuery of airdropQueryResults) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const airdropResult: any = airdropResultQuery.data;
    if (airdropResult !== null && responseIsValid(airdropResult)) {
      airdropResultInnerWithDrop.push(airdropResult);
    }
  }

  const [statusKeys, setStatusKeys] = useState<StatusKeyTuple[]>([]);
  useEffect(() => {
    void (async () => {
      const statusKeysResult: StatusKeyTuple[] = [];
      for (const airdropResult of airdropResultInnerWithDrop) {
        const [claimStatus] = await findClaimStatusKey(
          new u64(airdropResult.index),
          AIRDROP_MERKLE_DISTRIBUTOR_KEY
        );
        statusKeysResult.push({
          vaultKey: airdropResult.vaultKey,
          statusKey: claimStatus,
        });
      }
      // console.log("statuskey is now ", statusKeysResult);
      setStatusKeys(statusKeysResult);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airdropResultsLoaded, vaultKeysMemoKey]);

  const parsedStatusKeys = useAccountsData(
    statusKeys.map((sk) => sk.statusKey)
  );

  const isLoading =
    plotsWithVaultsAndMiners.length !== airdropQueryResults.length ||
    sbrAirdropIOUATA === undefined ||
    sbrAirdropIOUATA.loading ||
    parsedStatusKeys.includes(undefined) ||
    airdropResultInnerWithDrop.length !== parsedStatusKeys.length ||
    !airdropResultsLoaded;

  let totalAirdropAmount = 0;
  const airdropResponses: AirdropResponse[] = [];

  if (!isLoading) {
    airdropQueryResults.forEach((v) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const airdropResult: any = v.data;
      if (airdropResult !== null && responseIsValid(airdropResult)) {
        let relevantSkIndex = -1;
        for (let skIndex = 0; skIndex < statusKeys.length; skIndex++) {
          const skt = statusKeys[skIndex];
          if (skt && skt.vaultKey.equals(airdropResult.vaultKey)) {
            relevantSkIndex = skIndex;
          }
        }
        if (relevantSkIndex === -1) {
          if (!criticalErrorNotified) {
            criticalErrorNotified = true;
            notify({
              message: "This is a bug. Please report it on Discord",
              description: `Error finding status key`,
              type: "error",
            });
          }
        } else {
          if (parsedStatusKeys[relevantSkIndex] === undefined) {
            if (!criticalErrorNotified) {
              criticalErrorNotified = true;
              notify({
                message: "This is a bug. Please report it on Discord",
                description: `Status key was null but not undefined`,
                type: "error",
              });
            }
          } else if (parsedStatusKeys[relevantSkIndex] === null) {
            // null means not yet claimed
            airdropResponses.push(airdropResult);
            // Minus the performance fee
            totalAirdropAmount +=
              (Number(airdropResult.amount) * 0.84) / 10 ** 6;
          }
        }
      }
    });
  }

  if (
    sbrAirdropIOUATA !== undefined &&
    !sbrAirdropIOUATA.loading &&
    sbrAirdropIOUATA.isInitialized &&
    sbrAirdropIOUATA.balance.greaterThan(0)
  ) {
    totalAirdropAmount += Number(sbrAirdropIOUATA.balance.toExact());
  }

  // Note: This may fluctuate if someone was midway through a claim,
  // so do we freeze the number once an actionable amount is loaded?
  // Nope. I attempted to add this, and it wasn't worth it. Caused more problems
  // This is just a one time thing. I can't even test this

  const [lastWalletClaimed, setLastWalletClaimed] = useState<PublicKey | null>(
    null
  );

  const thisWalletAlreadyClaimed =
    wallet !== null &&
    lastWalletClaimed !== null &&
    wallet.publicKey.equals(lastWalletClaimed);

  return {
    airdropResponses,
    isLoading: isLoading,
    totalAirdropAmount,
    containsActionableItems:
      totalAirdropAmount > 0 && !thisWalletAlreadyClaimed,
    claimFinishedCallback: (walletKey: PublicKey) => {
      setLastWalletClaimed(walletKey);
    },
  };
};
