import type { Network } from "@saberhq/solana-contrib";
import type { IExchange } from "@saberhq/stableswap-sdk";
import { SWAP_PROGRAM_ID } from "@saberhq/stableswap-sdk";
import type { ChainId } from "@saberhq/token-utils";
import { networkToChainId, Token } from "@saberhq/token-utils";
import { useConnectionContext } from "@saberhq/use-solana";
import * as Sentry from "@sentry/react";
import { ENV } from "@solana/spl-token-registry";
import { keyBy, pickBy } from "lodash";
import { useEffect, useMemo } from "react";
import { createContainer } from "unstated-next";

import { useConfig } from "../contexts/config";
import type { PoolInfo, PoolsInfoData } from "./api/usePoolsInfo";
import { usePoolsInfo } from "./api/usePoolsInfo";
import { useTokenList } from "./api/useTokenList";
import { getMarketIfExists } from "./currencies";
import type { IEnvironment, PoolID } from "./environments";
import { TokenGroups } from "./tokenGroups";

export interface Pool
  extends IExchange,
    Partial<Pick<PoolInfo, "swap" | "plotKey">> {
  id: PoolID;
  name: string;
  /**
   * Icons per token
   */
  tokenIcons: readonly Token[];
  hidden?: boolean;
}

export interface KnownPool
  extends IExchange,
    Omit<PoolInfo, "tokens" | "tokenIcons" | "lpToken"> {
  /**
   * Tokens of the pool
   */
  tokens: readonly [Token, Token];
  /**
   * Icons per token
   */
  tokenIcons: readonly [Token, Token];
  /**
   * Underlying tokens
   */
  underlyingIcons: readonly [Token, Token];
}

export const envs = {
  "mainnet-beta": ENV.MainnetBeta,
  devnet: ENV.Devnet,
  testnet: ENV.Testnet,
} as const;

interface UseEnvironment {
  loading: boolean;
  name: string;
  endpoint: string;

  pools: Record<string, KnownPool>;

  tokens: readonly Token[];
  tokenGroups: TokenGroups;
  tokenMap: Map<string, Token> | null;
  chainId: ChainId | null;
  environments: { [N in Network]: IEnvironment };

  addresses?: PoolsInfoData["addresses"] | null;
}

const useEnvironmentInternal = (): UseEnvironment => {
  const { network } = useConnectionContext();
  useEffect(() => {
    Sentry.setContext("network", {
      network,
    });
  }, [network]);

  const { data: tokenListResponse } = useTokenList();
  const tokenList = tokenListResponse?.tokens;
  const { data: poolsInfoResponse } = usePoolsInfo();
  const poolsInfo = poolsInfoResponse?.pools;

  const { environments } = useConfig();
  const environment: IEnvironment = environments[network];
  const chainId: ChainId = useMemo(() => networkToChainId(network), [network]);

  const tokenMap: Map<string, Token> | null = useMemo(() => {
    if (!chainId || !tokenList) {
      return null;
    }
    const nextTokenMap = tokenList.reduce((map, item) => {
      const token = new Token({
        ...item,
      });
      map.set(item.address, token);
      return map;
    }, new Map<string, Token>());
    return nextTokenMap;
  }, [chainId, tokenList]);

  const pools = useMemo(() => {
    const result =
      poolsInfo
        // ?.filter((pool) => pool.id === PoolID.usdc_usdt)
        ?.map((pool): KnownPool => {
          return {
            ...pool,
            swapAccount: pool.swap.config.swapAccount,
            programID: SWAP_PROGRAM_ID,
            tokens: pool.tokens.map(
              (token) => new Token(token)
            ) as unknown as readonly [Token, Token],
            tokenIcons: pool.tokenIcons.map(
              (token) => new Token(token)
            ) as unknown as readonly [Token, Token],
            underlyingIcons: pool.underlyingIcons.map(
              (token) => new Token(token)
            ) as unknown as readonly [Token, Token],
            lpToken: new Token(pool.lpToken),
          };
        }) ?? [];
    return keyBy(
      pickBy(result, (x): x is KnownPool => !!x),
      (p) => p.id
    );
  }, [poolsInfo]);

  const tokens: Token[] = useMemo(() => {
    if (!tokenMap) {
      return [];
    }
    return [...tokenMap.values()];
  }, [tokenMap]);

  const tokenGroups = useMemo(() => {
    const nextTokenGroups = new TokenGroups();
    tokens.forEach((token) => {
      const market = getMarketIfExists(token);
      if (market) {
        nextTokenGroups.add(market, token);
      }
    });
    return nextTokenGroups;
  }, [tokens]);

  return {
    loading: false,
    name: environment.name,
    endpoint: environment.endpoint,
    pools,
    tokens,
    tokenGroups,
    tokenMap,
    chainId,
    environments,

    addresses: poolsInfoResponse?.addresses,
  };
};

export const { Provider: EnvironmentProvider, useContainer: useEnvironment } =
  createContainer(useEnvironmentInternal);
