import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useConnectedWallet } from "@saberhq/use-solana";
import { BN } from "bn.js";
import { useState } from "react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";

import { PlotProvider } from "../../../contexts/plot";
import { PoolProvider } from "../../../contexts/pool";
import { useSaberAirdrop } from "../../../contexts/saberAirdrop";
import { useSunnyPools } from "../../../contexts/sunnyPools";
import type { SunnyPool } from "../../../contexts/sunnyPools/useAllSunnyPools";
import { breakpoints } from "../../../theme/breakpoints";
import { AsyncButton } from "../../common/AsyncButton";
import { SunnyErrorBoundary } from "../../common/SunnyErrorBoundary";
import { MigrationItem, SaberAirdropItem } from "./MigrationItem";

const localStorageHideMigration =
  localStorage.getItem("hideMigration") !== null;

const asyncButton = css`
  width: 100%;
  margin-bottom: 6px;
  height: 44px;
`;

export const MigrationWidget: React.FC = () => {
  const wallet = useConnectedWallet();

  // V1: plots
  // V2: pools
  const { sunnyPools: plots, pools } = useSunnyPools();

  const plotsToMigrate = plots.filter((plot) => {
    return (
      // has a vault
      plot.vault &&
      // has a miner
      plot.miner &&
      // balance is non-zero in the internal miner
      (!plot.miner.accountInfo.data.balance.eq(new BN(0)) ||
        // miner has unclaimed wages
        !plot.miner.accountInfo.data.wagesEarned.eq(new BN(0)) ||
        // stakedBalance is non-zero in the vault
        !plot.vault.accountInfo.data.stakedBalance.eq(new BN(0)))
    );
  });

  const saberAirdropResult = useSaberAirdrop(plots, wallet);

  const [hideEnabled, setHideEnabled] = useState<boolean>(
    localStorageHideMigration
  );

  if (plotsToMigrate.length === 0 && hideEnabled) {
    return null;
  }

  if (plotsToMigrate.length > 0 && hideEnabled) {
    localStorage.removeItem("hideMigration");
    setHideEnabled(false);
  }

  if (saberAirdropResult.containsActionableItems && hideEnabled) {
    localStorage.removeItem("hideMigration");
    setHideEnabled(false);
  }

  const isLoading =
    wallet === null ||
    plots.length === 0 ||
    pools.length === 0 ||
    saberAirdropResult.isLoading;

  return (
    <MigrationWidgetContainer>
      {isLoading ||
      plotsToMigrate.length ||
      saberAirdropResult.containsActionableItems ? (
        <ExclamationWrapper>
          <FaExclamationTriangle />
        </ExclamationWrapper>
      ) : (
        <>
          <SuccessWrapper>
            <FaCheckCircle />
          </SuccessWrapper>
          <HideButton
            href="#hide"
            onClick={(e) => {
              e.preventDefault();
              localStorage.setItem("hideMigration", "hideMigration");
              setHideEnabled(true);
            }}
          >
            hide
          </HideButton>
        </>
      )}
      {isLoading ||
      plotsToMigrate.length ||
      saberAirdropResult.containsActionableItems ? (
        <h3>Migrate your deposits (September 2021)</h3>
      ) : (
        <h3>Nothing to migrate!</h3>
      )}

      <p>
        Saber upgraded to new staking pools in September 2021, which fixes the
        SBR emissions issue. You can use this tool to migrate the new pools and
        start earning SBR + SUNNY.
      </p>
      <p>
        For more information,{" "}
        <a
          href="https://medium.com/sunny-aggregator/sunny-has-migrated-to-new-saber-pools-d7e44853b4e9"
          target="_blank"
          rel="noreferrer"
        >
          read the Sunny announcement
        </a>
        .
      </p>
      <p>
        If you have migration problems,{" "}
        <a href="https://premigration.sunny.ag">
          try using the old version of the app at premigration.sunny.ag
        </a>
        .
      </p>
      <p>
        A SBR airdrop was also issued as compensation. If you are eligible for
        the SBR airdrop, it will be displayed in this box and can be claimed
        here.
      </p>
      <YDNA>Your deposits needing attention:</YDNA>

      {isLoading ? (
        <div>
          {wallet === null ? (
            <AsyncButton
              size="small"
              // disabled={disabled}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() => {}}
              css={asyncButton}
              // connectWalletOverride={"Claim all rewards"}
            >
              Connect Wallet
            </AsyncButton>
          ) : (
            <Garys>
              <ThrowawayAnimatedGaryShell>
                <ThrowawayAnimatedGaryShimmer></ThrowawayAnimatedGaryShimmer>
              </ThrowawayAnimatedGaryShell>
            </Garys>
          )}
        </div>
      ) : (
        <div>
          {saberAirdropResult.containsActionableItems ? (
            <SaberAirdropItem saberAirdropResult={saberAirdropResult} />
          ) : null}
          {plotsToMigrate.map((plot) => {
            const legacyMint = plot.pool.lpToken.mintAccount.toBase58();
            let sunnyPool: SunnyPool | undefined;
            for (const pool of pools) {
              // use the LP token mint to find the corresponding new pool
              const poolMint = pool.saberPool.lpToken.mintAccount.toBase58();
              if (poolMint === legacyMint) {
                sunnyPool = pool;
                break;
              }
            }
            return (
              <SunnyErrorBoundary key={plot.pool.name}>
                <PlotProvider initialState={{ plot }}>
                  <PoolProvider initialState={sunnyPool}>
                    <MigrationItem legacyPlot={plot} sunnyPool={sunnyPool} />
                  </PoolProvider>
                </PlotProvider>
              </SunnyErrorBoundary>
            );
          })}
          <Good>You have no deposits in old pools</Good>
        </div>
      )}
    </MigrationWidgetContainer>
  );
};

const MigrationWidgetContainer = styled.div`
  background: #fff;
  color: #223;
  border-radius: 8px;
  max-width: 480px;
  margin: 0 auto 40px auto;
  padding: 16px 24px 18px 24px;

  position: relative;
  ${breakpoints.mobile} {
    padding: 14px 18px 16px 18px;
  }
  p {
    margin-bottom: 0.5em;
  }
  h3 {
    color: #223;
  }
`;

const HideButton = styled.a`
  font-size: 15px;
  color: #888;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 24px;
  &:hover {
    text-decoration: underline;
  }
`;

const YDNA = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 18px;
`;

const ExclamationWrapper = styled.div`
  svg {
    display: block;
    color: hsl(45, 100%, 58%);
    height: 40px;
    width: 40px;
    margin: 0 auto;
  }
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
  height: 40px;
`;

const SuccessWrapper = styled.div`
  svg {
    display: block;
    color: hsl(123, 69.2%, 64.9%);

    height: 40px;
    width: 40px;
    margin: 0 auto;
  }
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
  height: 40px;
`;

// This is just a temporary file, so it's fine...
// 🐌
const ThrowawayAnimatedGaryShell = styled.span`
  position: relative;
  background: #eee;
  border-radius: 8px;
  padding: 0 16px;
  height: 44px;
  overflow: hidden;
  display: block;
  line-height: 1.2;
  margin-bottom: 4px;
`;
const ThrowawayAnimatedGaryShimmer = styled.span`
  position: absolute;
  left: -200px;
  right: 800px;
  top: 0;
  bottom: 0;
  width: 400px;
  background-image: linear-gradient(
    58deg,
    rgba(238, 238, 238, 0) 25%,
    rgba(255, 255, 255, 0.5) 46%,
    rgba(255, 255, 255, 0.5) 54%,
    rgba(238, 238, 238, 0) 75%
  );

  background-repeat: no-repeat;
  pointer-events: none;

  z-index: 0;
  animation: 1.8s linear 0s infinite running gary;
  @keyframes gary {
    from {
      transform: translateX(-200%);
      //   background-position: -100% 0px;
    }
    to {
      transform: translateX(200%);

      //   background-position: 100% 0px;
    }
  }
`;

const Garys = styled.div`
  margin-bottom: 6px;
`;

const Good = styled.div`
  background: hsl(123, 79.2%, 84.9%);
  border-radius: 8px;
  margin-bottom: 6px;
  font-size: 15px;
  padding: 10px 16px 10px 16px;
  font-weight: 600;
  text-align: center;
  display: none;
  &:first-of-type {
    display: block;
  }
`;
