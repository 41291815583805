import { useToken } from "@quarryprotocol/react-quarry";
import type { ParsedAccountDatum, ParsedAccountInfo } from "@saberhq/sail";
import { TokenAmount } from "@saberhq/token-utils";
import * as Sentry from "@sentry/react";
import type { MinerData, QuarryData } from "@sunnyaggregator/sunny-sdk";
import { Payroll, SUNNY_ADDRESS } from "@sunnyaggregator/sunny-sdk";
import BN from "bn.js";
import { useCallback, useMemo } from "react";

export interface UseSunnyMine {
  getClaimableAmount: (additionalMilliseconds?: number) => TokenAmount | null;
}

/**
 * Internal use only.
 * @returns
 */
export const useSunnyMine = ({
  quarry,
  miner,
}: {
  quarry?: ParsedAccountInfo<QuarryData>;
  miner: ParsedAccountDatum<MinerData>;
}): UseSunnyMine => {
  const sunny = useToken(SUNNY_ADDRESS);

  const payroll = useMemo(() => {
    const quarryData = quarry?.accountInfo.data;
    const minerData = miner?.accountInfo.data;
    return quarryData && minerData
      ? new Payroll(
          quarryData.famineTs,
          quarryData.lastUpdateTs,
          quarryData.dailyRewardsRate.div(new BN(86_400)),
          quarryData.rewardsPerTokenStored,
          new BN(quarryData.tokenMintDecimals),
          quarryData.totalTokensDeposited
        )
      : null;
  }, [miner?.accountInfo.data, quarry?.accountInfo.data]);

  const getClaimableAmount = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    (additionalMilliseconds: number = 0) => {
      if (!payroll || !miner || !sunny) {
        return null;
      }

      const minerData = miner.accountInfo.data;
      const now = new BN(
        Math.floor((new Date().getTime() + additionalMilliseconds) / 1_000)
      );
      const wages = payroll.calculateWagesEarned(
        now,
        minerData.balance,
        minerData.wagesPerTokenPaid,
        minerData.wagesEarned
      );
      try {
        return new TokenAmount(sunny, wages);
      } catch (e) {
        console.error(`Could not parse claimable amount`, e);
        Sentry.captureException(e as Error, {
          tags: {
            action: "plot.getClaimableAmount",
          },
          extra: {
            currentTS: now.toString(),
            tokensDeposited: minerData.balance.toString(),
            wagesPerTokenPaid: minerData.wagesPerTokenPaid.toString(),
            wagesEarned: minerData.wagesEarned.toString(),
            wages: wages.toString(),
          },
        });
        return null;
      }
    },
    [miner, payroll, sunny]
  );

  return {
    getClaimableAmount,
  };
};
