import { TOKEN_ACCOUNT_PARSER, useParsedAccountsData } from "@saberhq/sail";
import type { Percent, Token } from "@saberhq/token-utils";
import { TokenAmount } from "@saberhq/token-utils";
import type { PublicKey } from "@solana/web3.js";
import type { Plot } from "@sunnyaggregator/sunny-sdk";
import { useMemo } from "react";
import { createContainer } from "unstated-next";

import { calculatePlotStats } from "../../utils/farming/calculatePlotStats";
import { useGovernanceToken } from "../../utils/farming/useGovernanceToken";
import { useLandlordState } from "../../utils/farming/useLandlordState";
import { useSDK } from "../sdk";
import type { LegacySunnyPool } from "../sunnyPools";
import type { UseSunnyMine } from "./sunny";
import { useSunnyMine } from "./sunny";

export interface UsePlot {
  sunnyPool?: LegacySunnyPool;
  /**
   * Staked token
   */
  token: Token | null;
  /**
   * Loading
   */
  loading: boolean;
  /**
   * Number of rewards tokens per day.
   */
  rewardsPerDay: TokenAmount | null;
  /**
   * Key of the plot
   */
  plotKey?: PublicKey;
  /**
   * Plot info
   */
  plot: Plot | null;
  /**
   * Rewards share
   */
  rewardsShare: Percent | null;

  sunny: UseSunnyMine;

  totalDeposits: TokenAmount | null;

  /**
   * Number of tokens staked.
   */
  stakedAmount: TokenAmount | null;
}

const usePlotInternal = ({
  plot,
}: { plot?: LegacySunnyPool } = {}): UsePlot => {
  const token = plot?.pool.lpToken;
  const plotData = plot?.plot;

  const { sunnyMut } = useSDK();
  const { state: landlord } = useLandlordState();
  const owner = sunnyMut?.provider.wallet.publicKey;

  const farmer = plot?.farmer ?? null;
  const vault = plot?.vault;

  const { farmerTokenVault } = useMemo(
    () => ({
      farmerTokenVault: [farmer?.tokenVaultKey],
    }),
    [farmer]
  );
  const [farmerBalanceData] = useParsedAccountsData(
    farmerTokenVault,
    TOKEN_ACCOUNT_PARSER
  );

  const sbr = useGovernanceToken();
  const { rewardsShare, totalDeposits, rewardsPerDay } = useMemo(() => {
    return calculatePlotStats({
      landlord,
      plot: plotData?.accountInfo.data,
      farmer,
      token,
      sbr,
      farmerBalance: farmerBalanceData?.accountInfo.data.amount,
    });
  }, [
    farmer,
    farmerBalanceData?.accountInfo.data.amount,
    landlord,
    plotData?.accountInfo.data,
    sbr,
    token,
  ]);

  const stakedAmount = useMemo(() => {
    return vault && token
      ? new TokenAmount(token, vault.accountInfo.data.stakedBalance)
      : null;
  }, [token, vault]);

  const sunny = useSunnyMine({
    quarry: plot?.quarry,
    miner: plot?.miner,
  });

  const anyLoading =
    plotData === undefined ||
    (owner ? farmer === undefined : false) ||
    farmerBalanceData === undefined ||
    vault === undefined;
  const sunnyPool = plot;

  return {
    sunnyPool,
    token: token ?? null,
    rewardsPerDay,
    loading: anyLoading,
    plotKey: plotData?.accountId,
    plot: plotData?.accountInfo.data ?? null,
    rewardsShare,
    totalDeposits,
    // actions
    stakedAmount,
    sunny,
  };
};

/**
 * @deprecated use {@link usePool}
 */
export const { useContainer: usePlot, Provider: PlotProvider } =
  createContainer(usePlotInternal);
