import styled from "@emotion/styled";

import { breakpoints } from "../../../../theme/breakpoints";

export const Header: React.FC = () => {
  return (
    <SunWrapper>
      <IntroBox>
        <Tagline>Sunny Aggregator Airdrop and Migration</Tagline>
      </IntroBox>
    </SunWrapper>
  );
};

const SunWrapper = styled.header`
  margin-left: auto;
  margin-right: auto;

  padding-top: 74px;
  padding-bottom: 178px;
  z-index: 2;
  position: relative;

  ${breakpoints.medium} {
    // margin-top: -48px;
    // padding-top: 80px;
    // padding-bottom: 160px;
  }
  ${breakpoints.tablet} {
    padding-top: 52px;
    padding-bottom: 145px;
  }
  ${breakpoints.mobile} {
    padding-top: 52px;
    padding-bottom: 147px;
  }
`;

const IntroBox = styled.div`
  max-width: 1060px;
  margin: 0 auto;

  padding: 0 32px 16px 32px;

  ${breakpoints.mobile} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

// Social items already took some of the lighter color orange,
// so the gradient starts a bit more reddish
const Tagline = styled.h2`
  color: #e29034;
  font-size: 32px;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 8px;

  background: -webkit-linear-gradient(#ec4c27, #ec8834);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  ::selection {
    background: highlight;
    -webkit-text-fill-color: #fff;
  }

  ${breakpoints.tablet} {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
  ${breakpoints.mobile} {
    font-size: 26px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
`;
