import type { Network } from "@saberhq/solana-contrib";
import { createContainer } from "unstated-next";

import type { IEnvironment } from "../../utils/environments";
import { environments as environmentsConfig } from "../../utils/environments";

type EnvironmentsMap = { [N in Network]: IEnvironment };

interface UseConfig {
  environments: EnvironmentsMap;
}

const useConfigInternal = (): UseConfig => {
  return { environments: environmentsConfig };
};

export const { Provider: ConfigProvider, useContainer: useConfig } =
  createContainer(useConfigInternal);
