import { useNativeAccount } from "@saberhq/sail";
import { SignerWallet, SolanaProvider } from "@saberhq/solana-contrib";
import type { TokenAmount } from "@saberhq/token-utils";
import { useConnectedWallet, useConnectionContext } from "@saberhq/use-solana";
import type { PublicKey } from "@solana/web3.js";
import { Keypair } from "@solana/web3.js";
import type { Programs } from "@sunnyaggregator/sunny-sdk";
import {
  REWARDER_KEY,
  SUNNY_ADDRESSES,
  SunnySDK,
} from "@sunnyaggregator/sunny-sdk";
import { useMemo } from "react";
import { createContainer } from "unstated-next";

export type ProgramKey = keyof Programs;

export const useSDKInternal = (): {
  sunny: SunnySDK;
  sunnyMut: SunnySDK | null;
  owner: PublicKey | null;
  nativeBalance?: TokenAmount;
} => {
  const { connection, sendConnection } = useConnectionContext();
  const wallet = useConnectedWallet();

  const { sunny } = useMemo(() => {
    const provider = SolanaProvider.load({
      connection,
      sendConnection,
      wallet: new SignerWallet(Keypair.generate()),
      opts: {
        commitment: "recent",
      },
    });
    return {
      sunny: SunnySDK.load({
        provider,
        addresses: {
          ...SUNNY_ADDRESSES,
        },
        environment: {
          rewarder: REWARDER_KEY,
        },
      }),
    };
  }, [connection, sendConnection]);

  const { sunnyMut } = useMemo(() => {
    if (!wallet) {
      return { sunnyMut: null };
    }
    const provider = SolanaProvider.load({
      connection,
      sendConnection,
      wallet,
      opts: {
        commitment: "recent",
      },
    });
    return {
      sunnyMut: SunnySDK.load({
        provider,
        addresses: {
          ...SUNNY_ADDRESSES,
        },
        environment: {
          rewarder: REWARDER_KEY,
        },
      }),
    };
  }, [connection, sendConnection, wallet]);

  const owner = useMemo(
    () => sunnyMut?.provider.wallet.publicKey ?? null,
    [sunnyMut?.provider.wallet.publicKey]
  );
  const { nativeBalance } = useNativeAccount();

  return {
    owner,
    nativeBalance,
    sunny,
    sunnyMut: sunnyMut ?? null,
  };
};

export const { useContainer: useSDK, Provider: SDKProvider } =
  createContainer(useSDKInternal);
